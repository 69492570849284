import React, { useState } from 'react'
import PropTypes from 'prop-types'
import WizardInput from './WizardInput'

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
const AccountForm = ({
  register,
  errors,
  watch,
}) => {
  const [loading, setLoading] = useState(false)
  const titles = [
    'Mr',
    'Mrs',
    'Ms',
    'Miss',
    'Dr',
    'Prof',
  ]

  return (
    <>
      <WizardInput
        type="email"
        errors={errors}
        label="Email*"
        name="email"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('email', {
            required: 'Email field is required',
            pattern: {
              value: emailRegex,
              message: 'Email must be valid',
            },
          }),
        }}
      />

      {
        loading ? null : (
          <WizardInput
            type="select"
            options={titles}
            label="Title"
            name="title"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('title', {
                required: 'Title is required',
              }),
            }}
          />
        )
      }

      <WizardInput
        label="First Name"
        name="first_name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('first_name', {
            required: 'First Name is required',
          }),
        }}
      />
      <WizardInput
        label="Last Name"
        name="last_name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('last_name', {
            required: 'Last Name is required',
          }),
        }}
      />
    </>
  )
}

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
}

export default AccountForm
