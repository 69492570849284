import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import * as echarts from 'echarts/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import cn from 'classnames'

import useChart from 'hooks/useChart'
import useUtils from 'hooks/useUtils'

import reportService from 'services/report.service'
import useReport from 'hooks/useReport'

interface ReportBookingsPerMonthProps {
  className?: string;
}

function ReportBookingsPerMonth({ className }: ReportBookingsPerMonthProps) {
  const { makeBarChartOptions } = useChart()
  const { currentYear, yearOptions } = useReport()
  const { monthName } = useUtils()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [chartXAxis, setChartXAxis] = useState([])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    getResults(currentYear)
  }, [])

  /**
   * Get results
   */
  const getResults = async (year: number) => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getBookingsPerMonth({ year })

    setResults(data)
    setChartXAxis(data.map((result: any) => monthName(result.booking_month)))
    setChartData(data.map((result: any) => result.total_bookings))

    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Bookings Per Month</p>
          <Form.Group className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon="filter" className="me-2" />
            <Form.Select
              defaultValue={currentYear}
              onChange={(e: any) => getResults(e.target.value)}
              disabled={loading}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <hr />
        <div className="row g-5">
          <div className="col-md-5">
            <Table responsive striped hover>
              <thead>
                <tr className="text-center">
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Booking Month</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Total Bookings</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={2} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !results.length && (
                    <td colSpan={2} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {results.map((result: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td>{monthName(result.booking_month)}</td>
                    <td>{result.total_bookings}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-md-7">
            <ReactEChartsCore
              echarts={echarts}
              option={makeBarChartOptions(chartXAxis, chartData)}
              style={{ height: '18.75rem' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ReportBookingsPerMonth
