import { Card } from 'react-bootstrap'

import BookingTransfer from './BookingTransfer'

interface BookingTransfersProps {
  booking: any;
  className: string;
}

function BookingTransfers({ booking, className }: BookingTransfersProps) {
  const { arrival_transfer, departure_transfer } = booking

  return (
    (arrival_transfer || departure_transfer) && (
      <Card className={className}>
        <Card.Body>
          <p className="fw-bold text-900">Transfer Details</p>
          <div className="row row-cols-1 row-cols-md-2">
            <BookingTransfer transfer={arrival_transfer} />
            <BookingTransfer transfer={departure_transfer} />
          </div>
        </Card.Body>
      </Card>
    )
  )
}

export default BookingTransfers
