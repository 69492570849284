import { KeyboardEvent, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import OtpInput from 'react-otp-input'

import useAuth from 'hooks/useAuth'
import authService from 'services/auth.service'

interface LoginOtpFormProps {
  email: string;
}

function LoginOtpForm({ email }: LoginOtpFormProps) {
  const { setAccessToken } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const [resending, setResending] = useState(false)
  const [otp, setOtp] = useState('')

  /**
   * Check if the OTP is valid
   */
  const otpValid = useMemo(() => otp.length === 6, [otp])

  /**
   * Handle pasting OTP from clipboard.
   */
  const handleOnOTPPaste = (e: any) => {
    e.preventDefault()

    let pastedText = ''

    if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData('text/plain')
    }

    // Remove all non-numeric characters and limit to 6 characters
    pastedText = pastedText.replace(/\D/g, '').substring(0, 6)

    // Update the OTP input
    setOtp(pastedText)
  }

  /**
   * Handle key down event on OTP input.
   */
  const handleOTPKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      requestAccessToken()
    }
  }

  /**
   * Request an access token from the server by passing the email and OTP.
   */
  const requestAccessToken = async () => {
    setSubmitting(true)

    const { data, error }: any = await authService.authenticateViaLoginOtp({
      email,
      otp,
    })

    if (error) {
      setSubmitting(false)
      toast(error.message)
      return
    }

    // On success, store the access token in the local storage
    setAccessToken(data.token)

    toast('Logged in successfully!')

    // Redirect to the dashboard after 1 second
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }

  /**
   * Resend the OTP to the user's email.
   */
  const resendOtp = async () => {
    setOtp('')
    setResending(true)

    const { error }: any = await authService.requestLoginOtp({ email })

    setResending(false)

    if (error) {
      return toast(error.message)
    }

    return toast('OTP resent successfully!')
  }

  return (
    <div className="form-wrapper w-100 mx-auto mx-lg-0">
      <div className="card p-4">
        <div className="fw-bold fs-7 text-black">Authenticate Your Account</div>
        <div>Please enter the code sent to your email.</div>
        <div className="my-4">
          <OtpInput
            value={otp}
            skipDefaultStyles
            onChange={setOtp}
            numInputs={6}
            renderInput={(props, index) => (
              <>
                {index === 3 && (
                  <strong className="d-inline-block mx-3 fs-8">{'—'}</strong>
                )}
                <input
                  {...props}
                  type="number"
                  onPaste={handleOnOTPPaste}
                  onKeyDown={handleOTPKeyDown}
                  disabled={submitting}
                  className={`form-control app-otp-input otp-input-${index} input-spin-none`}
                />
              </>
            )}
          />
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-between gap-3 gap-sm-4">
          <button
            className="btn btn-primary w-100"
            disabled={resending || submitting || !otp || !otpValid}
            onClick={requestAccessToken}
          >
            {submitting ? 'Validating...' : 'Submit Code'}
          </button>
          <button
            className="btn btn-outline-primary w-100"
            disabled={resending || submitting}
            onClick={resendOtp}
          >
            {resending ? 'Resending...' : 'Resend Code'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default LoginOtpForm
