import React, { useContext } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContactWizardContext } from 'context/Context'
import PageHeader from 'components/common/PageHeader'
import SubtleBadge from 'components/common/SubtleBadge'
import createMarkup from '../../../../../helpers/createMarkup'

const TitleItem = ({ title, text }) => {
  return (
    <div className="d-flex mb-2">
      <h6 className="me-2"> {title} :</h6>
      <h6 className="text-700 mb-0">
        {text}
      </h6>
    </div>
  )
}
const SummaryForm = ({ reset, contact, isEditing }) => {
  const { setStep, setContact } = useContext(ContactWizardContext)

  const emptyData = () => {
    setStep(1)
    setContact({})
    reset()
  }

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <h5 className="mb-0">Summary</h5>
          </Col>
        </Row>
        <div className="mt-4">
          <TitleItem title="Registered At"
                     text={dayjs(contact.created_at).format('DD-MM-YYYY')} />
          <TitleItem title="Title" text={contact.title} />
          <TitleItem title="First Name" text={contact.first_name} />
          <TitleItem title="Last Name" text={contact.last_name} />
          <TitleItem title="Email" text={contact.email} />

          <TitleItem title="Phone" text={contact.phone} />
          <TitleItem title="Address 1" text={contact.address_1} />
          <TitleItem title="Address 2" text={contact.address_2} />
          <TitleItem title="City" text={contact.city} />
          <TitleItem title="County" text={contact.county} />
          <TitleItem title="Country" text={contact.country} />
          <TitleItem title="Postcode" text={contact.postcode} />
        </div>

        {
          !isEditing ? (
            <Button variant="danger" className="px-5 my-3" onClick={emptyData}>
              Start Over
            </Button>
          ) : null
        }
      </Col>
    </Row>
  )
}

export default SummaryForm
