import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Logo = ({
  at,
  width,
  className,
  textClass,
  type,
  ...rest
}) => {
  const navigate = useNavigate()
  const [logoText, setLogoText] = useState()
  const { pathname } = useLocation()

  const CRM = pathname.split('/')[1]

  const CRMData = {
    simplycruises: {
      logotext: 'Simply Cruises',
      baseurl: '/simplycruises',
    },
    simplyjourneys: {
      logotext: 'Simply Journeys',
      baseurl: '/simplyjourneys',
    },
  }
  const getLogoTextFn = () => CRMData[CRM]?.logotext ?? 'Excellent Holidays'
  const handleLogoClick = () => navigate(CRMData[CRM]?.baseurl ?? '/')

  return (
    <Link
      to="#"
      onClick={handleLogoClick}
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' },
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-4 mb-4': at === 'auth',
          },
          className,
        )}
      >
        {/*<img className="me-2" src={logo} alt="Logo" width={width} />*/}
        <span className={classNames('font-sans-serif',
          textClass)}>{getLogoTextFn()}</span>
      </div>
    </Link>
  )
}

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string,
  text: PropTypes.string,
}

Logo.defaultProps = { at: 'auth', width: 58 }

export default Logo
