import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import { deleteContact, toggleActive } from 'services/contact.service'
import { assignToContact } from 'services/booking.service'

import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import ConfirmationModal from 'components/common/ConfirmationModal'
import AddBookingModal from 'modules/simple-crm/components/contacts/contact-details/AddBookingModal'

const ContactDetailsHeader = ({
  contact,
  isEdit = false,
  handleFetchContact,
  handleGetBookingList,
  onNoteAddClick,
}) => {
  const navigate = useNavigate()
  const [assigningBooking, setAssigningBooking] = useState(false)
  const [active, setActive] = useState(false)
  const [showDeleteConfirmModal, setDeleteShowConfirmModal] = useState(false)
  const [showInactiveConfirmModal, setInactiveShowConfirmModal] = useState(
    false)
  const [addBookingModal, setAddBookingModal] = useState(false)

  useEffect(() => {
    if (contact) {
      setActive(contact.status === 'active')
    }
  }, [contact])

  if (!contact) {
    return null
  }

  /**
   * Navigate back to the contacts page
   */
  const goBack = () => navigate('/simplycruises/contacts')

  const handleShowDeleteConfirmModal = () => {
    setDeleteShowConfirmModal(true)
  }

  const handleToggleActive = () => {
    setInactiveShowConfirmModal(true)
  }

  const toggleActiveAction = async () => {
    const { data, error } = await toggleActive(contact.id)

    if (error) {
      toast(error.message || 'Unexpected Error')
      setInactiveShowConfirmModal(false)
      return
    }

    toast(`Contact is ${active ? 'inactive' : 'active'} now`)
    setActive(!active)
    setInactiveShowConfirmModal(false)
    handleFetchContact()
  }

  const handleDeleteContact = async () => {
    const { data, error } = await deleteContact(contact.id)

    if (error) {
      toast(error.message || 'Unexpected Error')
      return
    }

    if (data.success) {
      toast('Successfully Deleted')
    }

    goBack()
  }

  const handleAddBooking = async (form) => {
    // Disable the add booking input fields
    setAssigningBooking(true)

    const { error } = await assignToContact(contact.id, {
      booking_ref: form.ref,
      opening_amount: form.cruisePrice,
      initial_deposit: form.initialDeposit,
      booked_with: form.bookedWith,
    })

    // Re-enable the add booking input fields
    setAssigningBooking(false)

    if (error) {
      toast(error.message || 'Unexpected Error')
      return
    }

    toast(`Booking Added`)
    setAddBookingModal(false)
    handleGetBookingList()
  }

  const handleClickEdit = () => {
    navigate(`/simplycruises/contacts/${contact.id}/edit`)
  }

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-between-center">

        <IconButton
          onClick={goBack}
          variant="success"
          size="sm"
          icon="arrow-left"
        />
        <Flex>
          <ConfirmationModal
            show={showInactiveConfirmModal}
            handleClose={() => setInactiveShowConfirmModal(false)}
            handleConfirm={toggleActiveAction}
            confirmationText={`Do you really want to make this Contact ${active
              ? 'inactive'
              : 'active'}?`}
            confirmButtonText="Confirm"
          />
          <ConfirmationModal
            show={showDeleteConfirmModal}
            handleClose={() => setDeleteShowConfirmModal(false)}
            handleConfirm={handleDeleteContact}
            confirmationText="Do you really want to delete this Contact?"
            confirmButtonText="Delete"
          />
          <AddBookingModal
            contactId={contact?.id}
            show={addBookingModal}
            loading={assigningBooking}
            handleClose={() => setAddBookingModal(false)}
            handleConfirm={handleAddBooking}
            onSuccess={handleGetBookingList}
          />

          {
            isEdit ? (<></>) : (
              <>
                <IconButton
                  variant="success"
                  size="sm"
                  icon="plus"
                  iconAlign="middle"
                  className="d-none d-sm-inline-block me-2"
                  onClick={() => setAddBookingModal(true)}
                >
                  <span className="d-none d-md-inline-block ms-1">
                    Add Booking
                  </span>
                </IconButton>
                <IconButton
                  variant="success"
                  size="sm"
                  icon="file"
                  iconAlign="middle"
                  className="d-none d-sm-inline-block me-2"
                  onClick={onNoteAddClick}
                >
                  <span className="d-none d-md-inline-block ms-1">
                    Add Note
                  </span>
                </IconButton>
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="edit"
                  transform="shrink-2"
                  iconAlign="middle"
                  className="d-none d-sm-inline-block me-2"
                  onClick={handleClickEdit}
                >
                  <span className="d-none d-md-inline-block ms-1">Edit</span>
                </IconButton>
                <IconButton
                  variant="warning"
                  size="sm"
                  icon={active ? faBan : faCheckCircle}
                  transform="shrink-2"
                  iconAlign="middle"
                  className="d-none d-sm-inline-block me-2"
                  onClick={handleToggleActive}
                >
                  <span className="d-none d-md-inline-block ms-1">Make {active
                    ? 'Inactive'
                    : 'Active'}</span>
                </IconButton>
                <IconButton
                  variant="danger"
                  size="sm"
                  icon="trash-alt"
                  iconAlign="middle"
                  className="d-none d-sm-inline-block me-2"
                  onClick={handleShowDeleteConfirmModal}
                >
                  <span className="d-none d-md-inline-block ms-1">Delete</span>
                </IconButton>
                <Dropdown
                  align="end"
                  className="btn-reveal-trigger d-inline-block d-sm-none">
                  <Dropdown.Toggle split variant="falcon-default" size="sm">
                    <FontAwesomeIcon icon="ellipsis-v" className="fs-11" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="border py-0">
                    <div className="py-2">
                      <Dropdown.Item
                        className="d-sm-none"
                        onClick={() => setAddBookingModal(true)}
                      >
                        Add Booking
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-sm-none"
                        onClick={onNoteAddClick}
                      >
                        Add Note
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-sm-none"
                        onClick={handleClickEdit}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleToggleActive}
                        className="d-sm-none">
                        Make {active
                          ? 'Inactive'
                          : 'Active'}
                      </Dropdown.Item>
                      <Dropdown.Divider className="d-sm-none" />
                      <Dropdown.Item
                        className="text-danger"
                        onClick={handleShowDeleteConfirmModal}
                      >Delete</Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )
          }
        </Flex>
      </Card.Header>
    </Card>
  )
}

ContactDetailsHeader.propTypes = {
  handleShow: PropTypes.func,
  onNoteAddClick: PropTypes.func,
  handleFetchContact: PropTypes.func,
}

export default ContactDetailsHeader
