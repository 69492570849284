import { useContext, useEffect, useState } from 'react'
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { ContactWizardContext } from 'context/Context'
import { useAppContext } from 'Main'
import { postContact } from 'services/contact.service'
import { toast } from 'react-toastify'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import Flex from 'components/common/Flex'
import Spinner from 'components/common/Spinner'
import AccountForm from 'modules/simple-crm/components/contacts/wizard/AccountForm'
import PersonalForm from 'modules/simple-crm/components/contacts/wizard/PersonalForm'
import Success from 'modules/simple-crm/components/contacts/wizard/Success'
import IconButton from 'components/common/IconButton'
import WizardModal from 'modules/simple-crm/components/contacts/wizard/WizardModal'
import SuccessUpdate from 'modules/simple-crm/components/contacts/wizard/SuccessUpdate'
import DateForm from 'modules/simple-crm/components/contacts/wizard/DateForm'
import SummaryForm from 'modules/simple-crm/components/contacts/wizard/SummaryForm'

const STEP_DONE = 5

const WizardLayout = ({
  variant,
  validation,
  progressBar,
  initialContactData = null,
  fetchContactList = {},
}) => {
  const { isRTL } = useAppContext()
  const { contact, setContact, step, setStep } = useContext(
    ContactWizardContext)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
  } = useForm()

  useEffect(() => {
    if (initialContactData) {
      setValue('email', initialContactData.email)
      setValue('title', initialContactData.title)
      setValue('first_name', initialContactData.first_name)
      setValue('last_name', initialContactData.last_name)

      setValue('phone', initialContactData.phone)
      setValue('address_1', initialContactData.address_1)
      setValue('address_2', initialContactData.address_2)
      setValue('postcode', initialContactData.postcode)
      setValue('country', initialContactData.country)
      setValue('county', initialContactData.county)
      setValue('city', initialContactData.city)
      setValue('created_at', new Date(initialContactData.created_at))
    }

  }, [])

  const [modal, setModal] = useState(false)

  const navItems = [
    {
      icon: 'lock',
      label: 'Contact',
    },
    {
      icon: 'user',
      label: 'Personal',
    },
    {
      icon: 'calendar-alt',
      label: 'Registration',
    },
    {
      icon: 'list',
      label: 'Summary',
    },
    {
      icon: 'thumbs-up',
      label: 'Done',
    },
  ]

  const onSubmitData = async (data) => {
    setContact({ ...contact, ...data })
    const targetStep = step + 1

    if (targetStep === STEP_DONE) {
      setLoading(true)

      if (initialContactData) {
        data = {
          ...data,
          id: initialContactData.id,
        }
      }
      const { error } = await postContact(data)

      if (error) {
        setLoading(false)
        toast(error.message ?? 'Unexpected Error')
        return
      }

      setStep(targetStep)

      if (!initialContactData) {
        // fetch for new contact
        fetchContactList({ page: 1, globalFilter: '' })
      }

      setLoading(false)
    } else {
      setStep(targetStep)
    }
  }
  const onError = () => {
    if (!validation) {
      clearErrors()
      setStep(step + 1)
    }
  }

  // const toggle = () => setModal(!modal)

  const handleNavs = targetStep => {
    // if (step !== 5) {
    if (targetStep < step) {
      setStep(targetStep)
    } else {
      handleSubmit(onSubmitData, onError)()
    }
    // } else {
    //   toggle()
    // }
  }

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header>
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                <NavItemPill
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))
              : navItems.map((item, index) => (
                <NavItem
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 20} style={{ height: 2 }} />}
        {
          !loading ? (
            <Card.Body className="fw-normal px-md-6 py-4">
              {step === 1 && (
                <AccountForm
                  register={register}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                />
              )}
              {step === 2 && (
                <PersonalForm
                  register={register}
                  errors={errors}
                  setValue={setValue}
                />
              )}
              {step === 3 ? (
                <DateForm
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  contact={contact}
                />
              ) : null
              }
              {step === 4 ? (
                <SummaryForm
                  reset={reset}
                  contact={contact}
                  isEditing={!!initialContactData}
                />
              ) : null
              }
              {step === 5 ? (
                initialContactData
                  ? <SuccessUpdate />
                  : <Success reset={reset} />
              ) : null
              }

            </Card.Body>
          ) : <Spinner />
        }

        <Card.Footer
          className={classNames('px-md-6', {
            'd-none': step === 5,
            ' d-flex': step < 5,
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semibold', {
              'd-none': step === 1,
            })}
            onClick={() => {
              setStep(step - 1)
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
      </Form>
    </>
  )
}

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 5 ? step > index : step > 4,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  )
}

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index,
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  )
}

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
}

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

NavItem.propTypes = NavItemPill.propTypes

export default WizardLayout
