import { useState } from 'react'
import { Form, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAsyncDebounce } from 'react-table'

import { app } from 'config'
import AppClipboard from 'components/common/AppClipboard'
import SubtleBadge from 'components/common/SubtleBadge'
import toolService from 'services/tool.service'
import useBooking from 'hooks/useBooking'

function ToolBookingSearch() {
  const { formatViewRoute } = useBooking()

  const [bookings, setBookings] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * Debounce search input
   */
  const handleSearchInput = useAsyncDebounce<any>((e: any) => {
    if (e.target.value) {
      searchBookings(e.target.value)
    }
  }, 800)

  /**
   * Searches bookings by reference ID
   */
  const searchBookings = async (refId: string) => {
    setBookings([])
    setLoading(true)

    const { data }: any = await toolService.searchBookings(refId)

    setBookings(data)
    setLoading(false)
  }

  return (
    <>
      <div>
        <Form.Group className="mb-3">
          <Form.Label>Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter a booking reference"
            onInput={handleSearchInput}
          />
        </Form.Group>
      </div>
      <div>
        <Form.Label>Results</Form.Label>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">
                <h6 className="text-700 fw-semibold">ID</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">MSC Ref</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Status</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={3} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !bookings.length && (
                <td colSpan={3} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {bookings.map((booking) => (
              <tr key={booking.id}>
                <td className="text-primary fw-semibold">
                  <Link
                    to={formatViewRoute(booking.id)}
                    className="text-primary"
                  >
                    {app.bookingPrefix}
                    {booking.id}
                  </Link>
                </td>
                <td className="text-primary fw-semibold">
                  <Link
                    to={formatViewRoute(booking.id)}
                    className="text-primary"
                  >
                    {booking.ref_id}
                  </Link>
                  <AppClipboard text={booking.ref_id} />
                </td>
                <td>
                  <SubtleBadge
                    pill
                    bg={
                      (app.status as any)[booking.status]?.variant ||
                      'secondary'
                    }
                    className="fs-11"
                  >
                    {booking.status}
                  </SubtleBadge>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default ToolBookingSearch
