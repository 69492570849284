import { Col, Row } from 'react-bootstrap'
import DashboardDataOverTime from 'components/dashboards/DashboardDataOverTime'
import {
  getContactsOverTime,
  getBookingsOverTime,
  getMonthlyBookings,
  getMonthlyContacts,
  getMonthlyRevenue,
  getRevenueOverTime,
  getMonthlyPassengers,
  getPassengersOverTime,
} from 'services/dashboard.service'
import DashboardMonthlyEntries from 'components/dashboards/DashboardMonthlyEntries'

const Dashboard = () => {
  return (
    <Row className="g-3 mb-3">
      <Col md={12} xxl={6}>
        <DashboardMonthlyEntries
          title="New contacts per month"
          apiFn={getMonthlyContacts}
        />
      </Col>
      <Col md={12} xxl={6}>
        <DashboardDataOverTime
          title="Total Contacts Over Time"
          apiFn={getContactsOverTime}
        />
      </Col>
      <Col md={12} xxl={6}>
        <DashboardMonthlyEntries
          title="New bookings per month"
          apiFn={getMonthlyBookings}
        />
      </Col>
      <Col md={12} xxl={6}>
        <DashboardDataOverTime
          title="Total Bookings Over Time"
          apiFn={getBookingsOverTime}
        />
      </Col>
      <Col md={12} xxl={6}>
        <DashboardMonthlyEntries
          title="Revenue per month / year"
          apiFn={getMonthlyRevenue}
        />
      </Col>
      <Col md={12} xxl={6}>
        <DashboardDataOverTime
          title="Revenue Over Time"
          apiFn={getRevenueOverTime}
        />
      </Col>
      <Col md={12} xxl={6}>
        <DashboardMonthlyEntries
          title="Passengers per month / year"
          apiFn={getMonthlyPassengers}
        />
      </Col>
      <Col md={12} xxl={6}>
        <DashboardDataOverTime
          title="Passengers Over Time"
          apiFn={getPassengersOverTime}
        />
      </Col>
    </Row>
  )
}

export default Dashboard
