import { app } from 'config'
import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/auth`

/**
 * Authenticate user via login OTP
 */
export const authenticateViaLoginOtp = async (payload: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/login/otp/authenticate`,
      method: 'POST',
      data: { ...payload, app_id: app.appId },
    },
  })

/**
 * Get user data
 */
export const getUser = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/me`,
      method: 'GET',
    },
  })

/**
 * Logout user
 */
export const logout = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/logout`,
      method: 'DELETE',
    },
  })

/**
 * Request login OTP
 */
export const requestLoginOtp = async (payload: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/login/otp/request`,
      method: 'POST',
      data: { ...payload, app_id: app.appId },
    },
  })

const authService = {
  authenticateViaLoginOtp,
  getUser,
  logout,
  requestLoginOtp,
}

export default authService
