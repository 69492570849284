import { FC, ComponentType } from 'react'
import { useSelector } from 'react-redux'

import useAuth from 'hooks/useAuth'

interface AuthGuardProps {
  component: ComponentType;
}

const AuthGuard: FC<AuthGuardProps> = ({ component: Component }) => {
  const authenticated = useSelector((state: any) => state.auth.authenticated)

  const { clearAccessToken } = useAuth()

  // If the user is not authenticated...
  if (!authenticated) {
    // Clear the access token
    clearAccessToken()

    // Redirect to the login page.
    window.location.href = '/login'

    return null
  }

  return <Component />
}

export default AuthGuard
