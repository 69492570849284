import { FormEvent, ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'

import CurrencyInput from 'react-currency-input-field'
import DatePicker from 'react-datepicker'

import { app } from 'config'

import Flex from 'components/common/Flex'
import ledgerTransactionService from 'services/ledger-transaction.service'
import dayjs from 'dayjs'

const ENTRY_CHARGE = 'charge' // Positive amount entry
const ENTRY_PAYMENT = 'payment' // Negative amount entry

interface LedgerTransactionModalProps {
  transaction: any;
  show: boolean;
  ledgerId: string;
  onClose: Function;
  onSuccess: Function;
}

// Default values used to reset the form
const defaultForm: any = {
  description: '',
  type: '',
  amount: '',
  entryType: ENTRY_CHARGE,
  date: dayjs().toDate(),
}

const LedgerTransactionModal = ({
  transaction,
  ledgerId,
  onClose,
  onSuccess,
  show,
}: LedgerTransactionModalProps) => {
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({ ...defaultForm })

  // If the modal's visibility changes...
  useEffect(() => {
    // Mark the form as not validated when modal's visibility changes
    setValidated(false)

    if (show) {
      // Prefill the form with the transaction's data when showing the modal
      const amount = transaction?.amount || 0

      setForm({
        type: transaction?.type || app.ledgerTransactionType.CRUISE,
        description:
          transaction?.description || app.ledgerTransactionDesc.CRUISE,
        amount: amount ? Math.abs(amount) : '',
        entryType: amount < 0 ? ENTRY_PAYMENT : ENTRY_CHARGE,
        date: transaction?.date
          ? dayjs(transaction?.date).toDate()
          : dayjs().toDate(),
      })
    }
  }, [show])

  /**
   * Closes the modal and resets the form.
   */
  const closeModal = () => {
    setForm({ ...defaultForm })
    onClose()
  }

  /**
   * Handles the form submission.
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Mark the form as validated
    setValidated(true)

    const formEl = event.currentTarget

    // Check if the form is valid
    if (formEl.checkValidity()) {
      setLoading(true)

      const payload = {
        description: form.description,
        type: form.type,
        amount: +form.amount * (form.entryType === ENTRY_CHARGE ? 1 : -1),
        date: dayjs(form.date).format('YYYY-MM-DD'),
        ledger_id: ledgerId,
      }

      const request = transaction?.id
        ? ledgerTransactionService.update(transaction.id, payload)
        : ledgerTransactionService.create(payload)

      const { error } = await request

      setLoading(false)

      if (error) {
        toast(error.message || 'Unexpected Error')
        return
      }

      toast('Transaction saved!')

      // Inform the parent component that the transaction was saved
      onSuccess()

      closeModal()
    }
  }

  /**
   * Handles the change event of the transaction type.
   * Auto-fills the description based on the selected type.
   */
  const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value

    setForm({
      ...form,
      type,
      description: (app.ledgerTransactionDesc as any)[type],
    })
  }

  return (
    <Modal show={show} onHide={closeModal} keyboard={false}>
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          Transaction
        </Flex>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <label htmlFor="title">Transaction Type {form.type}</label>
            <Form.Select
              aria-label="Transaction Type"
              id="type"
              required
              name="type"
              value={form.type}
              disabled={loading}
              onInput={handleTypeChange}
            >
              <option disabled>Select transaction type</option>
              {Object.values(app.ledgerTransactionType).map((key) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <label htmlFor="title">Description</label>
            <Form.Control
              required
              disabled={loading}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setForm({ ...form, description: e.target.value })
              }
              value={form.description}
              placeholder={'Description'}
              name="description"
              type="text"
              id="description"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <label htmlFor="title">Amount</label>
            <CurrencyInput
              className="form-control"
              defaultValue={0}
              placeholder="0.00"
              required
              min={0}
              disabled={loading}
              value={form.amount}
              onValueChange={(amount) => setForm({ ...form, amount })}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <label htmlFor="title">Entry Type</label>
            <div className="d-flex gap-2">
              <Form.Check
                inline
                checked={form.entryType === ENTRY_CHARGE}
                onChange={() => setForm({ ...form, entryType: ENTRY_CHARGE })}
                type="radio"
                id={ENTRY_CHARGE}
                label="Charge"
                name="entryType"
                disabled={loading}
              />
              <Form.Check
                checked={form.entryType === ENTRY_PAYMENT}
                onChange={() => setForm({ ...form, entryType: ENTRY_PAYMENT })}
                inline
                type="radio"
                id={ENTRY_PAYMENT}
                label="Payment"
                name="entryType"
                disabled={loading}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <label htmlFor="title">Transaction Date</label>
            <DatePicker
              disabled={loading}
              selected={form.date}
              className="form-control"
              onChange={(e: any) => setForm({ ...form, date: e })}
              dateFormat="dd/MM/yyyy"
            />
          </Form.Group>
          <div className="d-flex justify-content-end gap-2">
            <Button
              type="button"
              disabled={loading}
              variant="light"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default LedgerTransactionModal
