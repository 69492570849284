import { createSlice, Slice } from '@reduxjs/toolkit'

export const reportSlice: Slice = createSlice({
  name: 'report',

  initialState: {
    dueBalanceDate: {
      year: null,
      month: null,
    },
  },

  reducers: {
    /**
     * Updates the selected due balance date.
     */
    updateDueBalanceDate: (state, { payload }) => {
      state.dueBalanceDate = payload
    },
  },
})

export const { updateDueBalanceDate } = reportSlice.actions

export default reportSlice.reducer as any
