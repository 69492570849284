import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import round from 'lodash.round'
import useUtils from 'hooks/useUtils'
import useBooking from 'hooks/useBooking'

interface BookingCostingsProps {
  booking: any;
  className?: string;
}

function BookingCostings({ booking, className }: BookingCostingsProps) {
  const { monify } = useUtils()
  const { computeProfit } = useBooking()

  const [open, setOpen] = useState(false)

  /**
   * Calculate the discount data
   */
  const discount = useMemo(() => {
    const gross = +booking.total_gross_price

    const amount = gross - booking?.totals?.booking_value
    const rawDiscount = (gross ? amount / gross : 0) * 100

    return {
      amount,
      rate: round(rawDiscount, 2),
    }
  }, [booking])

  return (
    <Card className={className}>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fw-bold text-900 mb-0">Costings</p>
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={open ? 'chevron-up' : 'chevron-down'}
            onClick={() => setOpen((prev) => !prev)}
          />
        </div>
        {open && (
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-xl-1 mt-3">
            <div>
              <label className="mb-0">MSC Price:</label>
              <p className="fw-bold text-800 mb-0">
                {monify(booking.total_gross_price)}
              </p>
            </div>
            <div className="mt-2">
              <label className="mb-0">Cost Price:</label>
              <p className="fw-bold text-800 mb-0">
                {monify(booking.total_net_price)}
              </p>
            </div>
            <div className="mt-2">
              <label className="mb-0">Discount:</label>
              <p className="fw-bold text-800 mb-0">
                {monify(discount.amount)} ({discount.rate}%)
              </p>
            </div>
            <div className="mt-2">
              <label className="mb-0">Profit:</label>
              <p className="fw-bold text-800 mb-0">
                {monify(computeProfit(booking))}
              </p>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default BookingCostings
