import { Col, Modal, Row } from 'react-bootstrap'
import Flex from 'components/common/Flex'

interface BookingSpecInstructionModalProps {
  service: any;
  show: boolean;
  onClose: () => void;
}

const BookingSpecInstructionModal = ({
  service,
  show,
  onClose,
}: BookingSpecInstructionModalProps) => {
  return (
    <Modal show={show} onHide={onClose} keyboard={false} size="lg">
      <Modal.Body>
        <Flex
          alignItems="center"
          className="py-3 justify-content-center fw-bold"
        >
          More Info
        </Flex>
        <Row className="g-3">
          <Col>
            <p className="fw-bold">
              {service.desc} ({service.code})
            </p>
            <div
              className="mt-3"
              dangerouslySetInnerHTML={{
                __html: service?.spec_instruction?.content,
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default BookingSpecInstructionModal
