import { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import ledgerService from 'services/ledger.service'

type Props = {
  ledgerId: number;
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

/**
 * Options for selecting a year. (Last 10 years)
 */
const yearOptions = Array.from(
  { length: 10 },
  (_, i) => new Date().getFullYear() - i
)

const LedgerSettlerModal = ({ show, onClose, ledgerId, onSuccess }: Props) => {
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [loading, setLoading] = useState(false)

  // If the modal's visibility changes...
  useEffect(() => {
    if (show) {
      setMonth('')
      setYear('')
    }
  }, [show])

  /**
   * Disables the submit button if the month or year are not selected,
   * or if the form is currently loading.
   */
  const disableSubmit = useMemo(() => {
    return !month || !year || loading
  }, [month, year, loading])

  /**
   * Mark the ledger as settled.
   */
  const settle = async () => {
    setLoading(true)

    const { error } = await ledgerService.settle(
      ledgerId,
      `${year}-${month}-15`
    )

    setLoading(false)

    if (error) {
      return toast(error.message || 'Failed to settle ledger.')
    }

    onSuccess()

    return toast('Ledger marked as settled.')
  }

  /**
   * Handle the modal close event.
   */
  const handleClose = () => {
    if (!loading) {
      onClose()
    }
  }

  return (
    <Modal show={show} onHide={handleClose} keyboard={false}>
      <Modal.Body>
        <div className="py-3">
          <div>
            <h5 className="mb-3">When was this ledger settled?</h5>
            <form className="d-inline-flex gap-3">
              <div>
                <label className="d-block mb-1">Month</label>
                <select
                  required
                  value={month}
                  className="form-control"
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="">Select a month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div>
                <label className="d-block mb-1">Year</label>
                <select
                  required
                  value={year}
                  className="form-control"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="">Select a year</option>
                  {yearOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex align-items-center py-3 gap-3">
          <Button
            variant="outline-secondary"
            className="px-4"
            onClick={handleClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            className="px-4"
            disabled={disableSubmit}
            onClick={settle}
          >
            {loading ? 'Settling...' : 'Settle'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LedgerSettlerModal
