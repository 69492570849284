import { useMemo, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap'

import toolService from 'services/tool.service'

function ToolPassengerMembership() {
  const [validated, setValidated] = useState(false)
  const [passenger, setPassenger] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [dob, setDob] = useState<string>('')

  /**
   * Searches for passenger membership details.
   */
  const checkMembership = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setValidated(true)
    const formEl = event.currentTarget

    if (!formEl.checkValidity()) {
      return
    }

    setPassenger(null)
    setLoading(true)

    const { data }: any = await toolService.checkPassengerMembership({
      firstname: firstname,
      lastname: lastname,
      dob,
    })

    setPassenger(data)
    setLoading(false)
  }

  /**
   * Memoized Passenger address.
   */
  const address = useMemo(
    () => passenger?.ParticipantData.ParticipantAddress,
    [passenger]
  )

  /**
   * Memoized Traveler info.
   */
  const travelerInfo = useMemo(
    () => passenger?.ParticipantData.FrequentTravellerInfo,
    [passenger]
  )

  /**
   * Memoized Passport data.
   */
  const passport = useMemo(
    () => passenger?.ParticipantData.PassportData,
    [passenger]
  )

  /**
   * Clears the form fields.
   */
  const clear = () => {
    setValidated(false)
    setFirstname('')
    setLastname('')
    setDob('')
    setPassenger(null)
  }

  return (
    <>
      <Form noValidate validated={validated} onSubmit={checkMembership}>
        <div className="row">
          <div className="col-xxl-4">
            <Form.Group className="mb-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
              <Form.Label className="my-0 w-md-25 w-xxl-auto">
                Firstname
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={firstname}
                onInput={(e) => setFirstname(e.currentTarget.value)}
                placeholder="Firstname"
              />
            </Form.Group>
          </div>
          <div className="col-xxl-4">
            <Form.Group className="mb-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
              <Form.Label className="my-0 w-md-25 w-xxl-auto">
                Lastname
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={lastname}
                onInput={(e) => setLastname(e.currentTarget.value)}
                placeholder="Lastname"
              />
            </Form.Group>
          </div>
          <div className="col-xxl-4">
            <Form.Group className="mb-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
              <Form.Label className="my-0 w-md-25 w-xxl-auto">DOB</Form.Label>
              <Form.Control
                type="date"
                required
                value={dob}
                onInput={(e) => setDob(e.currentTarget.value)}
                placeholder="Date of Birth"
              />
            </Form.Group>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button type="button" variant="outline-secondary" onClick={clear}>
            Clear
          </Button>
          <Button type="submit">Search</Button>
        </div>
      </Form>
      <div>
        <Form.Label>Results</Form.Label>
        <div className="fs-10 text-gray">
          {loading ? 'Finding membership details...' : ''}
        </div>
        <div>
          {!loading &&
            (passenger ? (
              <>
                <Form.Label className="fw-semibold">Address</Form.Label>
                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">Address1</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">City</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">ZipCode</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">TelephoneNo</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">Cellular</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">EmailId</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="fs-10">
                    <tr>
                      <td>{address.Address1}</td>
                      <td>{address.City}</td>
                      <td>{address.ZipCode}</td>
                      <td>{address.TelephoneNo}</td>
                      <td>{address.Cellular}</td>
                      <td>{address.EmailId}</td>
                    </tr>
                  </tbody>
                </Table>
                <Form.Label className="fw-semibold mt-3">
                  Frequent Traveller Info
                </Form.Label>
                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">Number</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">Club</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">Points</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="fs-10">
                    <tr>
                      <td>{travelerInfo.FrequentTravelerNumber}</td>
                      <td>{travelerInfo.Club}</td>
                      <td>{travelerInfo.Score}</td>
                    </tr>
                  </tbody>
                </Table>
                <Form.Label className="fw-semibold mt-3">
                  Passport Data
                </Form.Label>
                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">Number</h6>
                      </th>
                      <th scope="col">
                        <h6 className="text-700 fw-semibold">Expire Date</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="fs-10">
                    <tr>
                      <td>{passport.PassportNo}</td>
                      <td>{passport.PassportExpireDate}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              <div className="fs-10 text-gray">No data available.</div>
            ))}
        </div>
      </div>
    </>
  )
}

export default ToolPassengerMembership
