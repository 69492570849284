import AdvanceTableSearchBox
  from 'components/common/advance-table/AdvanceTableSearchBox'
import IconButton from 'components/common/IconButton'
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import NewContactModal from './NewContactModal'

const ContactsHeader = ({
  selectedRowIds,
  handleShow,
  selectedItems,
  setSearch,
  fetchContactList,
}) => {
  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">All Contacts</h6>
        </Col>
        <Col xs="auto">
          <AdvanceTableSearchBox
            className="input-search-width"
            handleOnChange={setSearch}
            placeholder="Search by name"
          />
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div
        className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="filter"
          transform="shrink-4"
          iconAlign="middle"
          onClick={handleShow}
          className="d-xl-none"
        >
          <span className="d-none d-sm-inline-block ms-1">Filter</span>
        </IconButton>
        <div
          className="bg-300 mx-3 d-none d-lg-block d-xl-none"
          style={{ width: '1px', height: '29px' }}
        ></div>
        {(selectedRowIds && Object.keys(selectedRowIds).length > 0) ||
          (selectedItems && selectedItems.length > 0) ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              <option value="refund">Refund</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <NewContactModal fetchContactList={fetchContactList} />
          </div>
        )}
      </div>
    </div>
  )
}

ContactsHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  handleShow: PropTypes.func,
  layout: PropTypes.string,
  selectedItems: PropTypes.array,
  handleTicketsSearch: PropTypes.func,
}

export default ContactsHeader
