import { callExternalApi } from 'services/external-api.service'
import qs from 'query-string'

const toolsEndpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/tools`
const ledgersEndpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/ledgers`

/**
 * Searches for bookings by reference ID.
 */
export const searchBookings = async (refId: string) =>
  callExternalApi({
    config: {
      url: `${toolsEndpoint}/booking-search?q=${refId}`,
      method: 'GET',
    },
  })

/**
 * Searches for passengers
 */
export const searchPassengers = async (searchKey: string) =>
  callExternalApi({
    config: {
      url: `${toolsEndpoint}/passenger-search?q=${searchKey}`,
      method: 'GET',
    },
  })

/**
 * Searches for ledgers by file reference
 */
export const searchLedgersByFileRef = async (searchKey: string) =>
  callExternalApi({
    config: {
      url: `${ledgersEndpoint}/search-by-file-ref?ref=${searchKey}`,
      method: 'GET',
    },
  })

/**
 * Checks passenger membership details
 */
export const checkPassengerMembership = async ($query: {
  firstname: string;
  lastname: string;
  dob: string;
}) =>
  callExternalApi({
    config: {
      url: `${toolsEndpoint}/passenger-membership?${qs.stringify($query)}`,
      method: 'GET',
    },
  })

const toolService = {
  checkPassengerMembership,
  searchBookings,
  searchLedgersByFileRef,
  searchPassengers,
}

export default toolService
