import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Nav, Offcanvas, Row, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import SimpleBar from 'simplebar-react'
import dayjs from 'dayjs'

import { app } from 'config'
import { useBreakpoints } from 'hooks/useBreakpoints'

import { getBookingList } from 'services/booking.service'
import { getContact } from 'services/contact.service'
import {
  resetBookingsCount,
  resetNotesCount,
  setBookingsCount,
} from 'store/slices/appSlice'

import IconButton from 'components/common/IconButton'
import Spinner from 'components/common/Spinner'
import SubtleBadge from 'components/common/SubtleBadge'

import BookingTableTitle from 'modules/simple-crm/components/bookings/BookingTableTitle'
import Bookings from 'modules/simple-crm/components/contacts/contact-details/Bookings'
import ContactDetailsHeader from 'modules/simple-crm/components/contacts/contact-details/ContactDetailsHeader'
import ContactProfile from 'modules/simple-crm/components/contacts/contact-details/ContactProfile'
import Notes from 'modules/simple-crm/components/notes/Notes'
import Tickets from 'modules/simple-crm/components/contacts/contact-details/Tickets'
import ToDoList from 'modules/simple-crm/components/contacts/contact-details/ToDoList'

const ContactDetails = () => {
  const { breakpoints } = useBreakpoints()

  const dispatch = useDispatch()
  const params = useParams()

  // Booking lists
  const [soonBookings, setSoonBookings] = useState([])
  const [futureBookings, setFutureBookings] = useState([])
  const [pastBookings, setPastBookings] = useState([])
  const [canceledBookings, setCanceledBookings] = useState([])

  const [contact, setContact] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [show, setShow] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)

  const bookingsCount = useSelector((state) => state.app.bookingsCount)

  const notesCount = useSelector(
    (state) => state.app.notesCount[app.morphKeys.CONTACT]
  )

  useEffect(() => {
    // Reset the notes count when the component mounts
    dispatch(resetNotesCount(app.morphKeys.CONTACT))

    // Reset the bookings count when the component mounts
    dispatch(resetBookingsCount())

    handleFetchContact()
    handleGetBookingList()
  }, [])

  const handleGetBookingList = async () => {
    setLoadingList(true)

    if (params.id) {
      const { data, error } = await getBookingList(params.id, {
        client_date: dayjs().format('YYYY-MM-DD'),
      })

      if (error) {
        setLoadingList(false)
        toast(error.message || 'Unexpected Error')
        return
      }

      const { soon, future, past, canceled } = data

      setSoonBookings(soon)
      setFutureBookings(future)
      setPastBookings(past)
      setCanceledBookings(canceled)

      // Set the visible bookings count
      dispatch(
        setBookingsCount(
          soon.length + future.length + past.length + canceled.length
        )
      )

      setLoadingList(false)
    }
  }

  const handleFetchContact = () => {
    if (params.id) {
      fetchContact(params.id)
    }
  }

  const fetchContact = useCallback(async (id) => {
    // Give this fetch an ID
    // const fetchId = ++fetchIdRef.current

    // Set the loading state
    setLoading(true)

    // Only update the data if this is the latest fetch
    // if (fetchId === fetchIdRef.current) {
    const { data, error } = await getContact(id)

    if (error) {
      setLoading(false)
      toast(error.message || 'Unexpected Error')
      return
    }

    setContact(data)
    setLoading(false)
  }, [])

  /**
   * Clicks the notes tab and shows the notes modal
   */
  const handleNoteAdd = () => {
    document
      .querySelector('.tab-contact-details [data-rr-ui-event-key="notes"]')
      ?.click()

    setShowNotesModal(true)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <ContactDetailsHeader
        onNoteAddClick={handleNoteAdd}
        handleShow={handleShow}
        contact={contact}
        handleFetchContact={handleFetchContact}
        handleGetBookingList={handleGetBookingList}
      />
      <Row className="g-3">
        {loading ? (
          <Spinner />
        ) : contact ? (
          <>
            <Col xxl={3} xl={4} className="order-xl-0">
              <div className="position-xl-sticky top-0">
                <ContactProfile contact={contact} />
                {breakpoints.down('xl') ? (
                  <Offcanvas
                    show={show}
                    onHide={handleClose}
                    placement="end"
                    className="dark__bg-card-dark"
                  >
                    <Offcanvas.Header closeButton className="bg-body-tertiary">
                      <h6 className="fs-9 mb-0 fw-semibold">To-do List</h6>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="scrollbar scrollbar-none-xl p-0">
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className="ms-x1 my-x1"
                      >
                        <span>Add</span>
                      </IconButton>
                      <div className="border-bottom border-xl-0 border-200"></div>
                      <ToDoList />
                    </Offcanvas.Body>
                  </Offcanvas>
                ) : (
                  <ToDoList />
                )}
              </div>
            </Col>
            <Col xxl={9} xl={8}>
              <Card>
                <Tab.Container defaultActiveKey="bookings">
                  <SimpleBar>
                    <Card.Header className="p-0 border-bottom">
                      <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="bookings"
                            className="d-flex align-items-center py-3 px-x1 mb-0"
                          >
                            <FontAwesomeIcon
                              icon="stream"
                              className="text-600 tab-icon"
                            />
                            <h6 className="text-600 mb-0 ms-1">
                              Bookings{' '}
                              {bookingsCount ? (
                                <SubtleBadge
                                  pill
                                  className="ms-1 bg-success text-white"
                                >
                                  {bookingsCount}
                                </SubtleBadge>
                              ) : (
                                ''
                              )}
                            </h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="tickets"
                            className="d-flex align-items-center py-3 px-x1 mb-0"
                          >
                            <FontAwesomeIcon
                              icon="ticket-alt"
                              className="text-600"
                            />
                            <h6 className="text-600 mb-0 ms-1">Tickets</h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="notes"
                            className="d-flex align-items-center py-3 px-x1 mb-0"
                          >
                            <FontAwesomeIcon
                              icon="file-alt"
                              className="text-600"
                            />
                            <h6 className="text-600 mb-0 ms-1">
                              Notes{' '}
                              {notesCount ? (
                                <SubtleBadge
                                  pill
                                  className="ms-1 bg-success text-white"
                                >
                                  {notesCount}
                                </SubtleBadge>
                              ) : (
                                ''
                              )}
                            </h6>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Header>
                  </SimpleBar>
                  <Card.Body className="bg-body-tertiary">
                    <Tab.Content>
                      <Tab.Pane eventKey="bookings">
                        <div className="d-flex flex-column gap-3">
                          <div>
                            <BookingTableTitle
                              status={app.status.BKD}
                              title="Sailing soon"
                            />
                            <div className="bg-white pt-2 border rounded">
                              <Bookings
                                loading={loadingList}
                                bookings={soonBookings}
                              />
                            </div>
                          </div>
                          <div>
                            <BookingTableTitle
                              status={app.status.BKD}
                              title="Future sailings"
                            />
                            <div className="bg-white pt-2 border rounded">
                              <Bookings
                                loading={loadingList}
                                bookings={futureBookings}
                              />
                            </div>
                          </div>
                          <div>
                            <BookingTableTitle
                              status={app.status.SLD}
                              title="Past sailings"
                            />
                            <div className="bg-white pt-2 border rounded">
                              <Bookings
                                loading={loadingList}
                                bookings={pastBookings}
                              />
                            </div>
                          </div>
                          <div>
                            <BookingTableTitle
                              status={app.status.CXL}
                              title="Cancelled sailings"
                            />
                            <div className="bg-white pt-2 border rounded">
                              <Bookings
                                loading={loadingList}
                                bookings={canceledBookings}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="tickets">
                        <Tickets />
                      </Tab.Pane>
                      <Tab.Pane eventKey="notes">
                        <Notes
                          onFormClose={() => setShowNotesModal(false)}
                          showNotesModal={showNotesModal}
                          noteableId={params.id}
                          noteableType={app.morphKeys.CONTACT}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Tab.Container>
              </Card>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  )
}

export default ContactDetails
