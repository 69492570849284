import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'
import { updateDueBalanceDate } from 'store/slices/reportSlice'
import { useDispatch } from 'react-redux'

import cn from 'classnames'

import useUtils from 'hooks/useUtils'
import reportService from 'services/report.service'
import useReport from 'hooks/useReport'

interface ReportDueBalancesByYearProps {
  className?: string;
}

function ReportDueBalancesByYear({ className }: ReportDueBalancesByYearProps) {
  const dispatch = useDispatch()

  const { monify, monthName } = useUtils()
  const { currentYear, yearOptions } = useReport()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [selectedYear, setSelectedYear] = useState(currentYear)

  // Get results on initial load, also when selected year changes
  useEffect(() => {
    getResults()
  }, [selectedYear])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getDueBalancesByYear({
      year: selectedYear,
    })

    setResults(data)
    setLoading(false)
  }

  /**
   * Update due balance date on store on month click
   */
  const handleMonthClick = (month: number) => {
    dispatch(
      updateDueBalanceDate({
        year: selectedYear,
        month,
      })
    )
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Due Balances By Year</p>
          <Form.Group className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon="filter" className="me-2" />
            <Form.Select
              defaultValue={currentYear}
              onChange={(e: any) => setSelectedYear(e.target.value)}
              disabled={loading}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <hr />
        <Table responsive striped hover>
          <thead>
            <tr className="text-center">
              <th scope="col">
                <h6 className="text-700 fw-semibold">Payment Month</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Total Owed</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={2} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !results.length && (
                <td colSpan={2} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {results.map((result: any, index: number) => (
              <tr key={index} className="text-center">
                <td>
                  <span
                    className="text-primary cursor-pointer fw-semibold hover:underline"
                    onClick={() => handleMonthClick(result.payment_month)}
                  >
                    {monthName(result.payment_month)}
                  </span>
                </td>
                <td>{monify(result.total_owed)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default ReportDueBalancesByYear
