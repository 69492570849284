import React from 'react'
import CartNotification from '../../../components/navbar/top/CartNotification'
// import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from '../../../components/navbar/top/ProfileDropdown'
import { Nav } from 'react-bootstrap'
// import NineDotMenu from './NineDotMenu';
import ThemeControlDropdown from './ThemeControlDropdown'

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <ThemeControlDropdown />
      <CartNotification />
      {/*<NotificationDropdown />*/}
      {/*<NineDotMenu />*/}
      <ProfileDropdown />
    </Nav>
  )
}

export default TopNavRightSideNavItem
