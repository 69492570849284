import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'react-bootstrap'

import AppClipboard from 'components/common/AppClipboard'
import useUtils from 'hooks/useUtils'

interface BookingContactProps {
  booking: any;
}

function BookingContact({ booking }: BookingContactProps) {
  const { formatPhoneNumber } = useUtils()

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-column flex-md-row justify-content-md-between gap-3">
        <div>
          <label className="d-block m-0 text-secondary">
            <FontAwesomeIcon icon="user" className="me-2" />
            Contact
          </label>
          <span className="fs-10 fw-bold">
            {booking?.contact?.first_name} {booking?.contact?.last_name}
          </span>
        </div>
        <div>
          <label className="d-block m-0 text-secondary">
            <FontAwesomeIcon icon="envelope" className="me-2" />
            E-mail address
          </label>
          <a
            href={`mailto:${booking?.contact?.email}`}
            className="fs-10 fw-bold"
          >
            {booking?.contact?.email}
          </a>
          <AppClipboard text={booking?.contact?.email} />
        </div>
        <div>
          <label className="d-block m-0 text-secondary">
            <FontAwesomeIcon icon="phone" className="me-2" />
            Phone
          </label>
          <a href={`tel:${booking?.contact?.phone}`} className="fs-10 fw-bold">
            {formatPhoneNumber(booking?.contact?.phone)}
          </a>
          <AppClipboard text={booking?.contact?.phone} />
        </div>
      </Card.Header>
    </Card>
  )
}

export default BookingContact
