import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

import { app } from 'config'
import { setNotesCount } from 'store/slices/appSlice'

import Flex from 'components/common/Flex'
import ConfirmationModal from 'components/common/ConfirmationModal'

import NoteFormModal from 'modules/simple-crm/components/notes/NoteFormModal'

import noteService from 'services/note.service'

interface NotesProps {
  className?: string;
  noteableType: string;
  noteableId: string;
  onFormClose: Function;
  showNotesModal: false;
}

const Notes = ({
  noteableType,
  noteableId,
  showNotesModal,
  onFormClose,
}: NotesProps) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [notes, setNotes] = useState<any>([])
  const [note, setNote] = useState<any>(null)
  const [showNotesForm, setShowNotesForm] = useState<any>(null)
  const [showConfirmation, setShowConfirmation] = useState<any>(null)

  // When the component mounts...
  useEffect(() => {
    fetchNotes()
  }, [])

  // When the `showNotesModal` prop changes...
  useEffect(() => {
    // Set the visibility of the notes form modal
    setShowNotesForm(showNotesModal)
  }, [showNotesModal])

  // Fetches all notes based on the noteable type and id.
  const fetchNotes = async () => {
    setLoading(true)

    const { data }: any = await noteService.getAllByNoteable(
      noteableType,
      noteableId
    )

    // Update the notes list
    setNotes(data)

    // Update the notes count in the app store
    dispatch(setNotesCount({ key: noteableType, count: data.length }))

    setLoading(false)
  }

  // Handles the note deletion confirmation.
  const handleConfirm = async () => {
    // Show the confirmation modal
    setShowConfirmation(false)

    // Start the loading state so that while the note is being deleted, the user knows that something is happening
    setLoading(true)

    await noteService.remove(note.id)

    toast('Note deleted successfully.')

    // Clear the note state
    setNote(null)

    // Fetch the notes again
    fetchNotes()
  }

  /**
   * Handles the event when the delete button is clicked.
   */
  const handleDeleteClick = (note: any) => {
    // Set the note to be deleted
    setNote(note)

    // Show the confirmation modal
    setShowConfirmation(true)
  }

  /**
   * Handles the event when the edit button is clicked.
   */
  const handleEditClick = (note: any) => {
    // Set the note to be edited
    setNote(note)

    // Show the notes form modal
    setShowNotesForm(true)
  }

  // When the modal is closed...
  const handleModalClose = () => {
    // Clear the note state
    setNote(null)

    // Hide the notes form modal
    setShowNotesForm(false)

    // Call the onFormClose callback
    onFormClose()
  }

  return (
    <>
      {loading ? (
        <p className="fs-10 m-0">Fetching notes...</p>
      ) : !notes.length ? (
        <p className="fs-10 m-0">No notes yet.</p>
      ) : (
        <Flex direction="column" className="gap-3">
          {notes.map((note: any) => {
            return (
              <div
                key={note.id}
                className="bg-white dark__bg-1100 p-x1 rounded-3 shadow-sm"
              >
                <Row className="flex-between-center">
                  <Col
                    xs={12}
                    md={7}
                    xl={12}
                    xxl={8}
                    className="order-1 order-md-0 order-xl-1 order-xxl-0"
                  >
                    <h5 className="mb-0 border-top border-md-0 border-xl-top border-xxl-0 mt-x1 mt-md-0 mt-xl-x1 mt-xxl-0 pt-x1 pt-md-0 pt-xl-x1 pt-xxl-0 border-200 border-xl-200">
                      {note.title}
                    </h5>
                  </Col>
                  <Col
                    xs={12}
                    md="auto"
                    xl={12}
                    xxl="auto"
                    className="d-flex flex-between-center"
                  >
                    <Dropdown
                      align="end"
                      className="btn-reveal-trigger d-inline-block"
                    >
                      <Dropdown.Toggle split variant="falcon-default" size="sm">
                        <FontAwesomeIcon icon="ellipsis-h" className="fs-11" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="border py-0">
                        <div className="py-2">
                          <Dropdown.Item onClick={() => handleEditClick(note)}>
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            className="text-danger"
                            onClick={() => handleDeleteClick(note)}
                          >
                            Delete
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                <p className="w-lg-75 w-xl-100 w-xxl-75 mb-0 border-top border-md-0 border-xl-top border-xxl-0 mt-2 pt-x1 pt-md-0 pt-xl-x1 pt-xxl-0 border-200 border-xl-200">
                  {note.content}
                </p>
                <div className="d-flex align-items-center justify-content-between mt-3 gap-3">
                  <h6 className="mb-0 fs-11">
                    <FontAwesomeIcon
                      icon="clock"
                      className="text-primary me-2"
                    />
                    {note.last_updated_by ? (
                      <>
                        <span className="text-500">{'Edited: '}</span>
                        <span className="text-600">
                          {dayjs(note.updated_at).format('DD MMM YYYY')}
                        </span>
                        <span className="text-500">{' at '}</span>
                        <span className="text-600">
                          {dayjs(note.updated_at).format('hh:mm A')}
                        </span>
                        <span className="text-500">{' by '}</span>
                        <span className="text-600">{note.last_updated_by}</span>
                      </>
                    ) : (
                      <>
                        <span className="text-500">{'Created: '}</span>
                        <span className="text-600">
                          {dayjs(note.created_at).format('DD MMM YYYY')}
                        </span>
                        <span className="text-500">{' at '}</span>
                        <span className="text-600">
                          {dayjs(note.created_at).format('hh:mm A')}
                        </span>
                        <span className="text-500">{' by '}</span>
                        <span className="text-600">
                          {note.author?.full_name || note.author_id}
                        </span>
                      </>
                    )}
                  </h6>
                  <small className="fw-bold fs-11 text-500">
                    {app.notePrefix}
                    {note.id}
                  </small>
                </div>
              </div>
            )
          })}
        </Flex>
      )}
      <NoteFormModal
        noteableType={noteableType}
        noteableId={noteableId}
        note={note}
        show={showNotesForm}
        onClose={handleModalClose}
        onSuccess={fetchNotes}
      />
      <ConfirmationModal
        show={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleConfirm={handleConfirm}
        confirmationText="Do you really want to delete this note?"
        confirmButtonText="Delete"
      />
    </>
  )
}

export default Notes
