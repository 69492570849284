import { Accordion, Card } from 'react-bootstrap'
import dayjs from 'dayjs'
import SubtleBadge from 'components/common/SubtleBadge'

interface BookingRemarksProps {
  className?: string;
  remarks: any[];
}

function BookingRemarks({ className, remarks }: BookingRemarksProps) {
  return remarks.length ? (
    <Card className={className}>
      <Card.Body>
        <p className="fw-bold text-900">Remarks</p>
        <Accordion>
          {remarks.map((remark, index) => (
            <Accordion.Item eventKey={`${index}`} key={remark.id}>
              <Accordion.Header>
                <p className="fw-medium fs-10 mb-0">
                  {dayjs(remark.entered_datetime).format(
                    'DD MMM YYYY [-] HH:mm'
                  )}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <SubtleBadge pill bg="success">
                    {remark.entered_by}
                  </SubtleBadge>
                </div>
                <p className="mt-3">{remark.description}</p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Card.Body>
    </Card>
  ) : (
    <Card className={className}>
      <Card.Body>
        <p className="fw-bold text-900">Remarks</p>
        <p className="text-center fs-10 text-800 text-nowrap">No remarks.</p>
      </Card.Body>
    </Card>
  )
}

export default BookingRemarks
