import { app } from 'config'
import useUtils from 'hooks/useUtils'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneDeparture, faShip } from '@fortawesome/free-solid-svg-icons'

const arrivalType = app.arrivalType as Record<string, string>

interface BookingTransferProps {
  transfer: any;
}

const Icon = ({ type }: { type: string }) => {
  const className = 'w-6 h-6 text-primary-400 dark:text-primary-300'

  switch (type) {
    case 'A':
      return (
        <FontAwesomeIcon
          icon={faPlaneDeparture}
          className={cn('text-primary me-1', className)}
        />
      )

    case 'P':
      return (
        <FontAwesomeIcon
          icon={faShip}
          className={cn('text-primary me-1', className)}
        />
      )

    default:
      return <span className="fw-bold">[{arrivalType[type] ?? type}]</span>
  }
}

function BookingTransfer({ transfer }: BookingTransferProps) {
  const { formatDate } = useUtils()

  return (
    transfer && (
      <div>
        <p className="fw-bold text-900 text-uppercase">{transfer.type}</p>
        <div className="d-flex flex-column">
          <div className="fw-bold text-800">
            {formatDate(transfer.date, 'DD MMM YYYY')}
          </div>
          <div className="flex gap-2">
            <Icon type={transfer.from_type} /> {transfer.from_location} to
          </div>
          <div className="flex gap-2">
            <Icon type={transfer.to_type} /> {transfer.to_location}
          </div>
        </div>
      </div>
    )
  )
}

export default BookingTransfer
