import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/notes`

/**
 * Creates a new note.
 */
export const create = async (payload: any) =>
  callExternalApi({
    config: {
      url: endpoint,
      method: 'POST',
      data: payload,
    },
  })

/**
 * Gets all notes based on noteable type and id.
 */
export const getAllByNoteable = async (
  noteableType: string,
  noteableId: string | number
) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${noteableType}/${noteableId}`,
      method: 'GET',
    },
  })

/**
 * Deletes a note.
 */
export const remove = async (id: string | number) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}`,
      method: 'DELETE',
    },
  })

/**
 * Updates a note.
 */
export const update = async (id: string | number, payload: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}`,
      method: 'PUT',
      data: payload,
    },
  })

const noteService = {
  create,
  getAllByNoteable,
  remove,
  update,
}

export default noteService
