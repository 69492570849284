import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import cn from 'classnames'

import useUtils from 'hooks/useUtils'
import reportService from 'services/report.service'
import useReport from 'hooks/useReport'

interface ReportCruiseRevenuePerMoProps {
  className?: string;
}

function ReportCruiseRevenuePerMo({
  className,
}: ReportCruiseRevenuePerMoProps) {
  const { currentYear, yearOptions } = useReport()
  const { monify, monthName } = useUtils()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  useEffect(() => {
    getResults(currentYear)
  }, [])

  /**
   * Get results
   */
  const getResults = async (year: number) => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getCruiseRevenuePerMo({ year })

    setResults(data)
    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Cruise Revenue Per Month</p>
          <Form.Group className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon="filter" className="me-2" />
            <Form.Select
              defaultValue={currentYear}
              onChange={(e: any) => getResults(e.target.value)}
              disabled={loading}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <hr />
        <Table responsive striped hover>
          <thead>
            <tr className="text-center">
              <th scope="col">
                <h6 className="text-700 fw-semibold">Month</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Total Amount</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={2} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !results.length && (
                <td colSpan={2} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {results.map((result: any, index: number) => (
              <tr key={index} className="text-center">
                <td>{monthName(result.month)}</td>
                <td>{monify(result.total_amount)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default ReportCruiseRevenuePerMo
