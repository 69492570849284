import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field'

import { app } from 'config'
import Flex from 'components/common/Flex'
import bookingService from 'services/booking.service'

/**
 * Default "assign booking" form
 */
const defaultForm = {
  ref: '',
  cruisePrice: '0.00',
  initialDeposit: '0.00',
  bookedWith: app.orgs.MT.key,
}

/**
 * Default "booking reference validation" result
 */
const defaultRefValidationResult = {
  in_use: false,
  booking_id: null,
  contact_id: null,
}

const AddBookingModal = ({
  contactId,
  show,
  handleClose,
  handleConfirm,
  loading,
  onSuccess,
}) => {
  const [form, setForm] = useState({ ...defaultForm })
  const [formValidated, setFormValidated] = useState(false)

  const [refValidating, setRefValidating] = useState(false)
  const [refValidated, setRefValidated] = useState(false)
  const [refValidationResult, setRefValidationResult] = useState({
    ...defaultRefValidationResult,
  })

  const [transferring, setTransferring] = useState(false)

  // If the modal's visibility changes...
  useEffect(() => {
    // Mark the form as not validated when modal's visibility changes
    setFormValidated(false)

    if (show) {
      // Reset the form values
      setForm({ ...defaultForm })
    }
  }, [show])

  // If the booking reference changes...
  useEffect(() => {
    // Reset the form validation
    resetRefValidationStates()
  }, [form.ref])

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    // Reset the form values
    setForm({ ...defaultForm })

    // Reset the ref validation states
    resetRefValidationStates()

    // Inform the parent component to close the modal
    handleClose()
  }

  /**
   * Assigns the booking to the contact.
   */
  const handleAssignment = async (event) => {
    event.preventDefault()

    const formEl = event.currentTarget

    if (formEl.checkValidity() !== false) {
      handleConfirm(form)
      setTimeout(() => {
        // Reset the form values
        setForm({ ...defaultForm })
      }, 500)
    } else {
      setFormValidated(true)
    }
  }

  /**
   * Transfers the booking to a new contact.
   */
  const handleTransfer = async () => {
    setTransferring(true)

    await bookingService.transferToContact(
      refValidationResult.booking_id,
      contactId
    )

    setTransferring(false)

    // Inform the parent component that the transfer was successful
    onSuccess(true)

    closeModal()
  }

  const resetRefValidationStates = () => {
    // Reset the form values
    setFormValidated(false)
    setRefValidating(false)
    setRefValidated(false)
    setRefValidationResult({ ...defaultRefValidationResult })
  }

  /**
   * Validates the file reference.
   */
  const validateBookingRef = async () => {
    setRefValidating(true)
    setRefValidated(false)

    const { data } = await bookingService.validateRef(form.ref)

    setRefValidating(false)
    setRefValidated(true)
    setRefValidationResult(data)
  }

  /**
   * Action buttons when a booking is already assigned to another contact.
   */
  const alreadyAssignedActions = () => (
    <>
      <p className="fs-10">
        This booking is already assigned to{' '}
        <span className="fw-bold">another</span> contact.
      </p>

      <Flex alignItems="center" className="justify-content-end gap-2">
        <Button
          size="sm"
          variant="light"
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          type="button"
          variant="success"
          disabled={refValidating || loading || transferring}
          onClick={handleTransfer}
        >
          Transfer here
        </Button>
        <a
          href={`/simplycruises/bookings/${refValidationResult.booking_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            size="sm"
            type="button"
            variant="primary"
            disabled={refValidating || loading || transferring}
          >
            Booking
            <FontAwesomeIcon icon="external-link-alt" className="ms-2" />
          </Button>
        </a>
        <a
          href={`/simplycruises/contacts/${refValidationResult.contact_id}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            size="sm"
            type="button"
            variant="info"
            disabled={refValidating || loading || transferring}
          >
            Contact
            <FontAwesomeIcon icon="external-link-alt" className="ms-2" />
          </Button>
        </a>
      </Flex>
    </>
  )

  /**
   * Action buttons when a booking is available for assignment.
   */
  const assignContactActions = () => (
    <Flex alignItems="center" className="justify-content-end gap-2">
      <Button variant="light" onClick={closeModal} disabled={loading}>
        Cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        disabled={loading}
        onClick={handleAssignment}
      >
        Confirm
      </Button>
    </Flex>
  )

  /**
   * Form when a booking is available for assignment.
   */
  const assignmentForm = () => (
    <>
      <p className="fs-10">
        This booking is <span className="fw-bold">available</span> for
        assignment.
      </p>
      <Form.Group className="mb-3 w-100">
        <Form.Label>Cruise Price</Form.Label>
        <CurrencyInput
          className="form-control"
          defaultValue={0}
          placeholder="0.00"
          required
          min={0}
          disabled={loading}
          value={form.cruisePrice}
          onValueChange={(cruisePrice) =>
            setForm({
              ...form,
              cruisePrice,
            })
          }
        />
      </Form.Group>
      <Form.Group className="mb-3 w-100">
        <Form.Label>Initial Deposit</Form.Label>
        <CurrencyInput
          className="form-control"
          defaultValue={0}
          placeholder="0.00"
          required
          min={0}
          disabled={loading}
          value={form.initialDeposit}
          onValueChange={(initialDeposit) =>
            setForm({
              ...form,
              initialDeposit,
            })
          }
        />
      </Form.Group>
    </>
  )

  /**
   * Action buttons when the booking reference is not yet validated.
   */
  const awaitingValidationActions = () => (
    <Flex alignItems="center" className="justify-content-end gap-2">
      <Button variant="light" onClick={closeModal} disabled={loading}>
        Cancel
      </Button>
      <Button
        type="button"
        variant="primary"
        disabled={!form.ref || refValidating || loading || transferring}
        onClick={validateBookingRef}
      >
        {refValidating ? 'Validating...' : 'Validate'}
      </Button>
    </Flex>
  )

  return (
    <Modal show={show} onHide={closeModal} keyboard={false}>
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          Add New Booking
        </Flex>
        <Form
          noValidate
          validated={formValidated}
          className="mt-4"
          onSubmit={(event) => event.preventDefault()}
        >
          <Flex direction="column">
            <Form.Group className="mb-3 w-100">
              <Form.Label>Booking Reference</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder={'Booking Reference'}
                value={form.ref}
                name="ref"
                onChange={({ target }) =>
                  setForm({
                    ...form,
                    ref: target.value,
                  })
                }
                disabled={loading || refValidating || transferring}
              />
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>Booking with</Form.Label>
              <Form.Select
                aria-label="Select Organization"
                value={form.bookedWith}
                onChange={(e) =>
                  setForm({ ...form, bookedWith: e.target.value })
                }
              >
                <option value={app.orgs.MT.key}>{app.orgs.MT.fullLabel}</option>
                <option value={app.orgs.EH.key}>{app.orgs.EH.fullLabel}</option>
              </Form.Select>
            </Form.Group>
            {refValidated && (
              <>
                {refValidationResult.in_use ? (
                  <>
                    {refValidationResult.contact_id === contactId ? (
                      <>
                        <p className="fs-10">
                          This booking is already assigned to{' '}
                          <span className="fw-bold">this</span> contact.
                        </p>
                        <div className="d-flex justify-content-end">
                          <Button variant="light" onClick={closeModal}>
                            Close
                          </Button>
                        </div>
                      </>
                    ) : (
                      alreadyAssignedActions()
                    )}
                  </>
                ) : (
                  assignmentForm()
                )}
              </>
            )}
          </Flex>
          {refValidated
            ? !refValidationResult.in_use && assignContactActions()
            : awaitingValidationActions()}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AddBookingModal
