import { useDispatch } from 'react-redux'
import { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import axios from 'axios'
import Router from 'router'
import is from 'is_js'

import { app } from 'config'
import { PageLoader } from 'components/page-loader'
import { CloseButton } from 'components/common/Toast'
import { useAppContext } from 'Main'
import { setAuthStatus, setUser } from 'store/slices/authSlice'

import SettingsPanel from 'components/settings-panel/SettingsPanel'
import SettingsToggle from 'components/settings-panel/SettingsToggle'
import authService from 'services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'assets/scss/global.scss'

export const App: FC = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  const config = useAppContext()
  const navbarPosition = config.navbarPosition

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    verifyAuth()
  }, [])

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
    if (is.safari()) {
      HTMLClassList.add('safari')
    }
  }, [HTMLClassList])

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout')
    }
    return () => HTMLClassList.remove('double-top-nav-layout')
  }, [navbarPosition])

  /**
   * Verifies the authentication status
   */
  const verifyAuth = async () => {
    // Get the access token from the local storage.
    const token = localStorage.getItem(app.accessTokenKey)

    // If the token is available...
    if (token) {
      // Set the Axios authorization header with the access token.
      axios.defaults.headers.common['Content-Type'] = 'application/json'
      axios.defaults.headers.common.Authorization = `Bearer ${token}`

      const { data: user } = await authService.getUser()

      // Update the global user state.
      dispatch(setUser(user))

      // Update the global authentication status state.
      dispatch(setAuthStatus(!!user))
    }

    setIsLoading(false)
  }

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    )
  }

  return (
    <>
      <Router />
      <SettingsToggle />
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
        theme="dark"
        limit={10}
      />
    </>
  )
}
