import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import { useAppContext } from 'Main'
import NavbarTop from 'components/navbar/top/NavbarTop'
import NavbarVertical from 'components/navbar/vertical/NavbarVertical'
import Footer from 'components/footer/Footer'
import siteMaps from 'modules/simple-journeys/routes/siteMaps'

const MainLayout = () => {
  const { hash, pathname } = useLocation()
  const navigate = useNavigate()
  const isKanban = pathname.includes('kanban')


  const isAuthenticated = true

  const {
    config: { isFluid, navbarPosition },
  } = useAppContext()

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    }, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (!isAuthenticated) {
    toast('You need to log in first')

    setTimeout(() => {
      navigate('/login')
    }, 300)
    return
  }

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical siteMaps={siteMaps} />
      )}

      <div className={classNames('content', { 'pb-0': isKanban })}>
        <NavbarTop />
        <Outlet />
        <Footer />
      </div>
    </div>
  )
}

export default MainLayout
