import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FalconCloseButton from 'components/common/FalconCloseButton'
import IconButton from 'components/common/IconButton'
import WizardForm from './wizard/Wizard'

const NewContactModal = ({ fetchContactList }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => setShow(true)

  return (
    <>
      <IconButton
        variant="success"
        size="sm"
        icon="plus"
        transform="shrink-3"
        iconAlign="middle"
        onClick={handleShow}
      >
        <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
          New
        </span>
      </IconButton>

      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Add Contact</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <WizardForm validation={true} fetchContactList={fetchContactList} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewContactModal
