import { FormEvent, ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'

import Flex from 'components/common/Flex'

import noteService from 'services/note.service'

interface NoteFormModalProps {
  note: any;
  show: boolean;
  noteableType: string;
  noteableId: string;
  onClose: Function;
  onSuccess: Function;
}

// Default values used to reset the form
const defaultForm = {
  title: '',
  content: '',
}

const NoteFormModal = ({
  note,
  noteableType,
  noteableId,
  onClose,
  onSuccess,
  show,
}: NoteFormModalProps) => {
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({ ...defaultForm })

  // If the modal's visibility changes...
  useEffect(() => {
    // Mark the form as not validated when modal's visibility changes
    setValidated(false)

    if (show) {
      // Prefill the form with the note's data when showing the modal
      setForm({
        title: note?.title || '',
        content: note?.content || '',
      })
    }
  }, [show])

  /**
   * Closes the modal and resets the form.
   */
  const closeModal = () => {
    setForm({ ...defaultForm })
    onClose()
  }

  /**
   * Handles the form submission.
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Mark the form as validated
    setValidated(true)

    const formEl = event.currentTarget

    // Check if the form is valid
    if (formEl.checkValidity()) {
      setLoading(true)

      const payload = {
        title: form.title,
        content: form.content,
        noteable_type: noteableType,
        noteable_id: noteableId,
      }

      const request = note?.id
        ? noteService.update(note.id, payload)
        : noteService.create(payload)

      const { error } = await request

      setLoading(false)

      if (error) {
        toast(error.message || 'Unexpected Error')
        return
      }

      toast('Note saved!')

      // Inform the parent component that the note was saved
      onSuccess()

      closeModal()
    }
  }

  return (
    <Modal show={show} onHide={closeModal} keyboard={false}>
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          Note
        </Flex>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <label htmlFor="title">Title</label>
            <Form.Control
              required
              disabled={loading}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setForm({ ...form, title: e.target.value })
              }
              value={form.title}
              placeholder={'Title'}
              name="title"
              type="text"
              id="title"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="content">Content</Form.Label>
            <Form.Control
              required
              disabled={loading}
              onInput={(e: ChangeEvent<HTMLInputElement>) =>
                setForm({ ...form, content: e.target.value })
              }
              value={form.content}
              name="content"
              id="content"
              placeholder={'Enter your notes...'}
              as="textarea"
              rows={5}
            />
          </Form.Group>
          <div className="d-flex justify-content-end gap-2">
            <Button
              type="button"
              disabled={loading}
              variant="light"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default NoteFormModal
