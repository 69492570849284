import { Card, Nav, Tab } from 'react-bootstrap'
import SimpleBar from 'simplebar-react'

import useUtils from 'hooks/useUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'

interface BookingFlightSegmentsProps {
  className?: string;
  segments: any[];
}

function BookingFlightSegments({
  className,
  segments,
}: BookingFlightSegmentsProps) {
  const { formatHundredthHours, formatDate } = useUtils()

  return (
    <Card className={className}>
      <Card.Body className="px-0 pb-0">
        <p className="fw-bold text-900" style={{ padding: '0 20px' }}>
          Flight Details
        </p>
        <Tab.Container defaultActiveKey="segment-0">
          <SimpleBar>
            <Card.Header className="p-0 border-bottom">
              <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details tabs-highlighted">
                {segments.map((segment, index: number) => (
                  <Nav.Item key={segment.id}>
                    <Nav.Link
                      eventKey={`segment-${index}`}
                      className="d-flex align-items-center py-3 px-x1 mb-0 bordered-active-tab"
                    >
                      <h6 className="text-600 mb-0 ms-1">
                        Flight Segment {segments.length > 1 ? index + 1 : ''}
                      </h6>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
          </SimpleBar>
          <Card.Body>
            <Tab.Content>
              {segments.map((segment, index) => (
                <Tab.Pane eventKey={`segment-${index}`} key={segment.id}>
                  <div
                    key={`segment-${segment.id}`}
                    className="d-flex flex-column flex-xl-row gap-3 gap-xl-5 gap-xxl-7"
                  >
                    <div>
                      <p className="fw-bold">
                        {segment.airline_code}
                        {segment.flight_no}
                      </p>
                      <p>{segment.airline_name}</p>
                      <p>{formatDate(segment.depart_date)}</p>
                    </div>
                    <div>
                      <p className="fw-bold d-xl-none">Departure</p>
                      <p>
                        {segment.from_airport_name} {segment.from_airport_code}
                      </p>
                      <p>{formatHundredthHours(segment.depart_time)}</p>
                      <p>{segment.cabin_class}</p>
                    </div>
                    <div className="d-none d-xl-flex flex-column flex-xl-row align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faAngleDoubleRight}
                        className="fs-8"
                      />
                    </div>
                    <div>
                      <p className="fw-bold d-xl-none">Arrival</p>
                      <p>
                        {segment.to_airport_name} {segment.to_airport_code}
                      </p>
                      <p>{formatHundredthHours(segment.arrive_time)}</p>
                    </div>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card.Body>
    </Card>
  )
}

export default BookingFlightSegments
