import SubtleBadge from 'components/common/SubtleBadge'

interface BookingTableTitleProps {
  status: any;
  title: string;
}

function BookingTableTitle({ status, title }: BookingTableTitleProps) {
  return (
    <div className="d-flex align-items-center gap-2 mb-2">
      <SubtleBadge pill bg={status.variant} className="fs-11">
        {status.key}
      </SubtleBadge>
      <p className="fw-bold fs-10 m-0">{title}</p>
    </div>
  )
}

export default BookingTableTitle
