import { useCallback, useEffect, useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getContact } from 'services/contact.service'
import Spinner from 'components/common/Spinner'
import ContactDetailsHeader from 'modules/simple-crm/components/contacts/contact-details/ContactDetailsHeader'
import WizardForm from 'modules/simple-crm/components/contacts/wizard/Wizard'

const EditContactPage = () => {
  const params = useParams()
  const [contact, setContact] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleFetchContact()
  }, [])

  const handleFetchContact = () => {
    if (params.id) {
      fetchContact(params.id)
    }
  }

  const fetchContact = useCallback(async (id) => {
    // Give this fetch an ID
    // const fetchId = ++fetchIdRef.current

    // Set the loading state
    setLoading(true)

    // Only update the data if this is the latest fetch
    // if (fetchId === fetchIdRef.current) {
    const { data, error } = await getContact(id)

    if (error) {
      setLoading(false)
      toast(error.message || 'Unexpected Error')
      return
    }

    setContact(data)
    setLoading(false)
  }, [])

  return (
    <Row>
      <ContactDetailsHeader contact={contact} isEdit={true} />
      {loading ? (
        <Spinner />
      ) : contact ? (
        <Card>
          <div className="position-xl-sticky top-0">
            <WizardForm validation={true} initialContactData={contact} />
          </div>
        </Card>
      ) : null}
    </Row>
  )
}

export default EditContactPage
