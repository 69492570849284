import { createSlice, Slice } from '@reduxjs/toolkit'

export const authSlice: Slice = createSlice({
  name: 'auth',

  initialState: {
    user: null,

    authenticated: false,
  },

  reducers: {
    // Sets authentication status
    setAuthStatus: (state, { payload }) => {
      state.authenticated = payload
    },

    // Sets user data
    setUser: (state, { payload }) => {
      state.user = payload
    },
  },
})

export const { setAuthStatus, setUser } = authSlice.actions

export default authSlice.reducer as any
