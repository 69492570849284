import { useEffect, useMemo, useState } from 'react'
import { Button, Col, Modal, Row, Spinner, Table } from 'react-bootstrap'
import dayjs from 'dayjs'

import Flex from 'components/common/Flex'

import useBooking from 'hooks/useBooking'
import useUtils from 'hooks/useUtils'

import itineraryService from 'services/itinerary.service'

interface ItineraryModalProps {
  booking: any;
  show: boolean;
  onClose: Function;
}

const ItineraryModal = ({ booking, onClose, show }: ItineraryModalProps) => {
  const { itineraryMapFromCode } = useBooking()
  const { formatHundredthHours } = useUtils()

  const [itinerarySegments, setItinerarySegments] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  // Returns the itinerary map image URL
  const itineraryMap = useMemo(
    () => itineraryMapFromCode(booking?.itinerary_code),
    [booking]
  )

  // If the modal's visibility changes...
  useEffect(() => {
    if (show) {
      fetchItinerarySegments()
    }
  }, [show])

  /**
   * Fetches the itinerary segments for a given cruise ID.
   */
  const fetchItinerarySegments = async () => {
    setLoading(true)

    const { data }: any = await itineraryService.getSegmentsByCruiseId(
      booking.cruise_id
    )

    setItinerarySegments(data)
    setLoading(false)
  }

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    onClose()
  }

  return (
    <Modal show={show} onHide={closeModal} keyboard={false} size="xl">
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          Full Itinerary
        </Flex>
        <Row className="g-3">
          <Col xxl={3} xl={4} className="order-xl-0">
            <div className="d-flex justify-content-center mt-xl-5 mb-4">
              <a href={itineraryMap} target="_blank" rel="noreferrer">
                <img
                  src={itineraryMap}
                  className="img-fluid"
                  alt="Itinerary Map"
                />
              </a>
            </div>
          </Col>
          <Col xxl={9} xl={8}>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th scope="col">
                    <h6 className="text-700 fw-bold">Day</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-bold">Date</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-bold">Port</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-bold">Arrival</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-bold">Departure</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      <Spinner />
                    </td>
                  </tr>
                ) : !itinerarySegments.length ? (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-center fs-10 text-800 text-nowrap"
                    >
                      No itinerary segments.
                    </td>
                  </tr>
                ) : (
                  itinerarySegments.map((segment) => (
                    <tr key={`segment-${segment.id}`}>
                      <td className="fs-10 text-800 fw-bold text-nowrap">
                        {segment.itinerary_weekday}
                      </td>
                      <td className="fs-10 text-800 fw-semibold text-nowrap">
                        {dayjs(segment.itinerary_date).format('DD/MM/YYYY')}
                      </td>
                      <td className="fs-10 text-800 fw-semibold text-nowrap">
                        {segment.itinerary_port_name}
                      </td>
                      <td className="fs-10 text-800 fw-semibold text-nowrap">
                        {formatHundredthHours(segment.arrival_time)}
                      </td>
                      <td className="fs-10 text-800 fw-semibold text-nowrap">
                        {formatHundredthHours(segment.departure_time)}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <Button variant="falcon-default" onClick={closeModal}>
                Close
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ItineraryModal
