interface InputErrorProps {
  error?: string;
}

/**
 * Component for displaying input errors.
 */
function InputError({ error }: InputErrorProps) {
  return error ? (
    <small className="text-danger">{error}</small>
  ) : null
}

export default InputError
