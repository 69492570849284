import { useState } from 'react'

import InputError from 'components/forms/InputError'
import authService from 'services/auth.service'

interface LoginFormProps {
  // Callback function when the OTP was sent.
  onSent: (email: string) => void;
}

function LoginForm({ onSent }: LoginFormProps) {
  const [email, setEmail] = useState<string>('')
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState(false)

  /**
   * Handles the login form submission.
   */
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setLoading(true)
    setErrors(null)

    const { error }: any = await authService.requestLoginOtp({ email })
    setLoading(false)

    if (error) {
      return setErrors({ email: error.message })
    }

    onSent(email)
  }

  return (
    <div className="form-wrapper w-100 mx-auto mx-lg-0">
      <div className="card p-4">
        <div className="fw-bold fs-7 text-black">Welcome back</div>
        <div>Please enter your email to sign in to your account.</div>
        <form onSubmit={handleLogin}>
          <div className="form-group mt-3">
            <label className="mb-1 fw-bold">Email</label>
            <input
              type="email"
              required
              className="form-control"
              placeholder="Enter your email"
              value={email}
              onInput={(e) => setEmail(e.currentTarget.value)}
            />
            <InputError error={errors?.email} />
          </div>
          <div className="mt-4">
            <button className="btn btn-primary w-100" disabled={loading}>
              {loading ? 'Requesting OTP...' : 'Sign in to your account'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginForm
