import ReportCruiseRevenuePerMo from 'modules/simple-crm/components/reports/ReportCruiseRevenuePerMo'
import ReportDueBalancesByYear from 'modules/simple-crm/components/reports/ReportDueBalancesByYear'
import ReportRevenuePerSailing from 'modules/simple-crm/components/reports/ReportRevenuePerSailing'
import ReportRevenuePerShip from 'modules/simple-crm/components/reports/ReportRevenuePerShip'
import ReportBookingsPerMonth from 'modules/simple-crm/components/reports/ReportBookingsPerMonth'
import ReportBookingsPerShip from 'modules/simple-crm/components/reports/ReportBookingsPerShip'
import ReportBookingsPerStatus from 'modules/simple-crm/components/reports/ReportBookingsPerStatus'
import ReportBookingsWithPendingTickets from 'modules/simple-crm/components/reports/ReportBookingsWithPendingTickets'
import ReportDueBalancesByMonth from 'modules/simple-crm/components/reports/ReportDueBalancesByMonth'
import ReportBookingsLedgerStatus from 'modules/simple-crm/components/reports/ReportBookingsLedgerStatus'

import * as echarts from 'echarts/core'
import { BarChart, PieChart } from 'echarts/charts'
import { LegendComponent } from 'echarts/components'

echarts.use([BarChart, PieChart, LegendComponent])

function ReportsPage() {
  return (
    <>
      <div className="row g-3">
        <div className="col-6">
          <ReportCruiseRevenuePerMo className="h-100" />
        </div>
        <div className="col-6">
          <ReportRevenuePerShip className="h-100" />
        </div>
        <div className="col-6">
          <ReportDueBalancesByYear className="h-100" />
        </div>
        <div className="col-6">
          <ReportDueBalancesByMonth className="h-100" />
        </div>
        <div className="col-6">
          <ReportBookingsWithPendingTickets className="h-100" />
        </div>
        <div className="col-12">
          <ReportRevenuePerSailing className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsPerMonth className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsPerShip className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsPerStatus className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsLedgerStatus className="h-100" />
        </div>
      </div>
    </>
  )
}

export default ReportsPage
