import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'

interface ToolOptionProps {
  activeTool: string;
  toolKey: string;
  title: string;
  icon: any;
  onClick: (toolKey: string, title: string) => void;
}

function ToolOption({
  activeTool,
  toolKey,
  title,
  icon,
  onClick,
}: ToolOptionProps) {
  return (
    <li className="py-3" onClick={() => onClick(toolKey, title)}>
      <div className="d-flex align-items-center gap-2">
        <FontAwesomeIcon
          icon={icon}
          className={cn({ 'text-primary': activeTool === toolKey })}
        />
        <span
          className={cn(
            { 'tool-active': activeTool === toolKey },
            'hover:text-primary fw-semibold cursor-pointer'
          )}
        >
          {title}
        </span>
      </div>
    </li>
  )
}

export default ToolOption
