import { Route, Routes } from 'react-router-dom'

import Error404 from 'components/errors/Error404'
import Login from 'components/authentication/split/Login'
import AuthGuard from 'components/auth-guard'
import CRMSelectionPage from 'components/CRMSelectionPage'

import ErrorLayout from 'layouts/ErrorLayout'
import SelectCRMLayout from 'layouts/SelectCRMLayout'

import SimpleCRMRoutes from 'modules/simple-crm/routes/index'
import SimpleJourneysRoutes from 'modules/simple-journeys/routes/index'

const Router = () => {
  return (
    <Routes>
      <Route path='' element={<AuthGuard component={SelectCRMLayout} />}>
        <Route path='/' element={<AuthGuard component={CRMSelectionPage} />} />
      </Route>

      {SimpleCRMRoutes}
      {SimpleJourneysRoutes}

      <Route path="/login" element={<Login />} />

      <Route element={<ErrorLayout />}>
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  )
}

export default Router
