import { useState } from 'react'

import LoginForm from 'components/authentication/LoginForm'
import LoginIntro from 'components/authentication/LoginIntro'
import LoginOtpForm from 'components/authentication/LoginOtpForm'

import imgLogo from 'assets/img/logo_full_blue.svg'
import 'assets/scss/login.scss'

function LoginPage() {
  const [email, setEmail] = useState<string>('')

  return (
    <div
      id="page-login"
      className="d-flex flex-column justify-content-center bg-200"
    >
      <div className="page-container w-100 mx-auto px-3 px-sm-4">
        <div className="d-flex justify-content-center justify-content-lg-start">
          <img src={imgLogo} alt="" className="page-logo" />
        </div>
        <div className="d-flex flex-column flex-lg-row mt-5 gap-5">
          <LoginIntro />
          {email ? (
            <LoginOtpForm email={email} />
          ) : (
            <LoginForm onSent={setEmail} />
          )}
        </div>
      </div>
    </div>
  )
}

export default LoginPage
