import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'

import useBooking from 'hooks/useBooking'
import ItineraryModal from 'modules/simple-crm/components/itinerary/ItineraryModal'

interface BookingItineraryProps {
  booking: any;
}

function BookingItinerary({ booking }: BookingItineraryProps) {
  const { itineraryMapFromCode } = useBooking()

  const [showItinerary, setShowItinerary] = useState(false)

  // Returns the itinerary map image URL
  const itineraryMap = useMemo(
    () => itineraryMapFromCode(booking.itinerary_code),
    [booking?.itinerary_code]
  )

  return (
    <>
      {itineraryMap && (
        <Card className="mt-3">
          <Card.Body>
            <p className="fw-bold text-900">Itinerary Map</p>
            <a href={itineraryMap} target="_blank" rel="noreferrer">
              <img
                src={itineraryMap}
                className="img-fluid"
                alt="Itinerary Map"
              />
            </a>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p
                className="fs-10 text-primary text-decoration-underline cursor-pointer m-0"
                onClick={() => setShowItinerary(true)}
              >
                <FontAwesomeIcon icon="map" className="me-2" />
                View Full Itinerary
              </p>
              <p className="fs-10 m-0 fw-semibold text-800">
                {booking?.itinerary_code}
              </p>
            </div>
          </Card.Body>
        </Card>
      )}
      <ItineraryModal
        booking={booking}
        show={showItinerary}
        onClose={() => setShowItinerary(false)}
      />
    </>
  )
}

export default BookingItinerary
