import { Card, Col, Row } from 'react-bootstrap'
import { FiUser, FiUsers } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import useAuth from 'hooks/useAuth'

const CRMSelectionPage = () => {
  const navigate = useNavigate()

  const { userCan } = useAuth()

  const modules = [
    {
      icon: <FiUser className="text-primary fs-7" />,
      color: 'primary',
      title: 'Simply Cruises',
      url: '/simplycruises',
      description: 'Here is description for Simply Cruises.',
      canAccess: userCan('access:simplycrm'),
    },
    {
      icon: <FiUsers className="text-info fs-7" />,
      color: 'info',
      title: 'Simply Journeys',
      url: '/simplyjourneys',
      description: 'Here is description for Simply Journeys..',
      canAccess: userCan('access:simplyjourneys'),
    },
  ]

  return (
    <Card className="mt-3">
      <Card.Header className="border-bottom border-200">
        <h6 className="mb-0">Select a CRM</h6>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          {modules.map((item) => (
            <Col md={6} xxl={4} key={item.title}>
              <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
                <div
                  className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-${item.color}-subtle`}
                >
                  {item.icon}
                </div>
                <h5 className="mt-3 mb-2">
                  <span
                    onClick={() => (item.canAccess ? navigate(item.url) : null)}
                    className={classNames(
                      'stretched-link',
                      item.canAccess
                        ? 'text-900 cursor-pointer hover-primary'
                        : 'text-400'
                    )}
                  >
                    {item.title}
                  </span>
                </h5>
                <h6 className="w-75 mx-auto text-600 mb-x1">
                  {item.description}
                </h6>
              </div>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  )
}

export default CRMSelectionPage
