import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { Card, Table } from 'react-bootstrap'

import AppTooltip from 'components/common/AppTooltip'
import useUtils from 'hooks/useUtils'
import BookingSpecInstructionModal from 'modules/simple-crm/components/bookings/BookingSpecInstructionModal'

interface BookingOnboardServicesProps {
  className?: string;
  services: any[];
}

function BookingOnboardServices({
  className,
  services,
}: BookingOnboardServicesProps) {
  const { monify } = useUtils()

  const [instance, setInstance] = useState(null)

  return (
    <>
      <Card className={className}>
        <Card.Body>
          <p className="fw-bold text-900">Onboard Services</p>
          <Table responsive striped hover>
            <thead>
              <tr>
                <th scope="col">
                  <h6 className="text-700">Service</h6>
                </th>
                <th scope="col">
                  <h6 className="text-700">Passengers</h6>
                </th>
                <th scope="col">
                  <h6 className="text-700">Cost</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {services.length ? (
                services.map((service) => (
                  <tr key={`service-${service.id}`}>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      <div>
                        <div className="d-flex justify-content-between align-items-end gap-3">
                          <div className="flex-1">
                            <p className="m-0 fw-bold">{service.code}</p>
                            <p className="m-0">{service.desc}</p>
                          </div>
                          <AppTooltip
                            title={`Click for details`}
                            id={`service-${service.id}-tt`}
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="cursor-pointer fs-9"
                              onClick={() => setInstance(service)}
                            />
                          </AppTooltip>
                        </div>
                      </div>
                    </td>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      {service.passengers.map((passenger: any) => (
                        <div
                          key={`service-${service.id}-passenger-${passenger.id}`}
                        >
                          {passenger.first_name} {passenger.last_name}
                        </div>
                      ))}
                    </td>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      {monify(service.price)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={3}
                    className="text-center fs-10 text-800 text-nowrap"
                  >
                    No onboard services.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {instance && (
        <BookingSpecInstructionModal
          service={instance}
          show={true}
          onClose={() => setInstance(null)}
        />
      )}
    </>
  )
}

export default BookingOnboardServices
