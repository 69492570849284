import { Button, Modal } from 'react-bootstrap'
import Flex from './Flex'

const ConfirmationModal = ({
  show,
  handleClose,
  handleConfirm,
  confirmationText = 'Are you sure with this action?',
  confirmButtonText = 'Yes',
  modalCancelText = 'Cancel',
  loading = false,
}) => {
  return (
    <Modal show={show} onHide={handleClose} keyboard={false}>
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          <h5 className="mb-1 text-center">{confirmationText}</h5>
        </Flex>
        <Flex
          alignItems="center"
          className="py-3 justify-content-center gap-3 py-3"
        >
          <Button
            variant="outline-primary"
            className="px-5"
            onClick={handleClose}
            disabled={loading}
          >
            {modalCancelText}
          </Button>
          <Button
            variant="danger"
            className="px-5"
            onClick={handleConfirm}
            disabled={loading}
          >
            {confirmButtonText}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmationModal
