import { useState } from 'react'
import { Form, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAsyncDebounce } from 'react-table'

import { app } from 'config'
import AppClipboard from 'components/common/AppClipboard'
import toolService from 'services/tool.service'
import useBooking from 'hooks/useBooking'

function ToolPassengerSearch() {
  const { formatViewRoute } = useBooking()

  const [passengers, setPassengers] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  /**
   * Debounce search input
   */
  const handleSearchInput = useAsyncDebounce<any>((e: any) => {
    if (e.target.value) {
      searchPassengers(e.target.value)
    }
  }, 800)

  /**
   * Searches for passengers
   */
  const searchPassengers = async (searchKey: string) => {
    setPassengers([])
    setLoading(true)

    const { data }: any = await toolService.searchPassengers(searchKey)

    setPassengers(data)
    setLoading(false)
  }

  return (
    <>
      <div>
        <Form.Group className="mb-3">
          <Form.Label>Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search for a passenger..."
            onInput={handleSearchInput}
          />
        </Form.Group>
      </div>
      <div>
        <Form.Label>Results</Form.Label>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">
                <h6 className="text-700 fw-semibold">ID</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Full name</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Passenger No.</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Booking</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={4} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !passengers.length && (
                <td colSpan={4} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {passengers.map((passenger) => (
              <>
                {passenger.bookings.map((booking: any) => (
                  <tr className="text-primary fw-semibold">
                    <td>
                      {app.passengerPrefix}
                      {passenger.id}
                    </td>
                    <td>
                      {passenger.first_name} {passenger.last_name}
                    </td>
                    <td>
                      {passenger.passenger_no}
                      <AppClipboard text={passenger.passenger_no} />
                    </td>
                    <td>
                      <Link
                        to={formatViewRoute(booking.id)}
                        className="text-primary"
                      >
                        {`${app.bookingPrefix}${booking.id} - ${booking.ref_id}`}
                      </Link>
                      <AppClipboard text={booking.ref_id} />
                    </td>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default ToolPassengerSearch
