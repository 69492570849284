import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/ledger-transactions`

/**
 * Creates a new ledger transaction.
 */
export const create = async (payload: any) =>
  callExternalApi({
    config: {
      url: endpoint,
      method: 'POST',
      data: payload,
    },
  })

/**
 * Deletes a ledger transaction.
 */
export const remove = async (id: string | number) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}`,
      method: 'DELETE',
    },
  })

/**
 * Updates a ledger transaction.
 */
export const update = async (id: string | number, payload: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}`,
      method: 'PUT',
      data: payload,
    },
  })

const ledgerTransactionService = {
  create,
  remove,
  update,
}

export default ledgerTransactionService
