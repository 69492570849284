import dayjs from 'dayjs'
import { byThousands } from 'helpers/utils'
import round from 'lodash.round'

export const useUtils = () => {
  /**
   * Formats a date as a string with the provided format.
   */
  const formatDate = (date: any, format = 'DD-MM-YYYY') =>
    dayjs(date).format(format)

  const formatHundredthHours = (strTime: string) => {
    if (!strTime) {
      return null
    }

    if (strTime.length !== 4) {
      return strTime
    }

    // Extract hours and minutes
    const hours = strTime.substring(0, 2)
    const minutes = strTime.substring(2, 4)

    // Return the time in HH:MM format
    return `${hours}:${minutes}`
  }

  /**
   * Formats a number as a monetary value with two decimal places.
   * If the provided amount is falsy, it defaults to 0.
   */
  const monify = (amount: number, options: any = {}) => {
    const sign = options.sign ?? '£'

    if (!amount) {
      amount = 0
    }

    // Round the amount to two decimal places
    let newAmount = round(amount, 2).toFixed(2)

    // Add commas to the number
    newAmount = byThousands(newAmount)

    // Return the formatted amount with the sign
    return `${sign} ${newAmount}`
  }

  /**
   * Formats a phone number as a string with a space after the first five digits.
   */
  const formatPhoneNumber = (phone: string) => {
    if (!phone) {
      return ''
    }

    if (phone.startsWith('+')) {
      return phone
    }

    return `${phone.substring(0, 5)} ${phone.substring(5, 12)}`
  }

  const monthName = (monthNo: number, format = 'MMM') =>
    dayjs()
      .month(monthNo - 1)
      .format(format)

  return {
    byThousands,
    formatDate,
    formatHundredthHours,
    formatPhoneNumber,
    monify,
    monthName,
  }
}

export default useUtils
