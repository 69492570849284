import { Route } from 'react-router-dom'

import AuthGuard from 'components/auth-guard'

import Dashboard from 'modules/simple-crm/pages/dashboard-page'
import MainLayoutSimplyCruises from 'modules/simple-crm/MainLayout'
import ContactsPage from 'modules/simple-crm/pages/Contacts'
import ContactPage from 'modules/simple-crm/pages/Contacts/details'
import EditContactPage from 'modules/simple-crm/pages/Contacts/edit'
import BookingDetailsPage from 'modules/simple-crm/pages/Bookings/details'
import ReportsPage from 'modules/simple-crm/pages/Reports/page'
import ToolsPage from 'modules/simple-crm/pages/Tools/page'

const Router = (
  <>
    <Route path="simplycruises" element={<AuthGuard component={MainLayoutSimplyCruises} />}>
      <Route path="" element={<Dashboard />} />

      {/* START: Contact-related routes */}
      <Route path="contacts" element={<ContactsPage />} />
      <Route path="contacts/:id" element={<ContactPage />} />
      <Route path="contacts/:id/edit" element={<EditContactPage />} />
      {/* END: Contact-related routes */}

      <Route path="bookings/:id" element={<BookingDetailsPage />} />
      <Route path="reports" element={<ReportsPage />} />
      <Route path="tools" element={<ToolsPage />} />
    </Route>
  </>
)

export default Router
