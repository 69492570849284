import { FormEvent, ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'

import Flex from 'components/common/Flex'
import bookingService from 'services/booking.service'

interface BookingSendTicketModalProps {
  closeModal: () => void;
  booking: any;
  show: boolean;
  onSentToContact: () => void;
}

const BookingSendTicketModal = ({
  closeModal,
  booking,
  show,
  onSentToContact,
}: BookingSendTicketModalProps) => {
  const [loading, setLoading] = useState(false)
  const [recipient, setRecipient] = useState('')
  const [sendToAdmin, setSendToAdmin] = useState(true)
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    // Mark the form as not validated when modal's visibility changes
    setValidated(false)

    if (show) {
      setRecipient(booking?.contact?.email || '')
      setSendToAdmin(true)
    }
  }, [show])

  /**
   * Handles the form submission.
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const formEl = event.currentTarget
    event.preventDefault()

    // Mark the form as validated
    setValidated(true)

    // Check if the form is valid
    if (formEl.checkValidity()) {
      setLoading(true)

      const { error } = await bookingService.sendTicket(booking.id, {
        email: recipient,
        send_to_admin: sendToAdmin,
      })

      setLoading(false)

      if (error) {
        setValidated(false)
        toast(error?.message || 'Error: Failed to send ticket.')
        return
      }

      // If the ticket was sent to the contact, call the `onSentToContact` callback
      if (recipient === booking?.contact?.email) {
        onSentToContact()
      }

      toast('e-Ticket successfully sent!')

      closeModal()
    }
  }

  return (
    <>
      <Modal show={show} onHide={closeModal} keyboard={false}>
        <Modal.Body>
          <Flex alignItems="center" className="py-3 justify-content-center">
            Send e-Ticket
          </Flex>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <label htmlFor="recipient">Recipient email address</label>
              <Form.Control
                required
                disabled={loading}
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  setRecipient(e.target.value)
                }
                value={recipient}
                placeholder={'Recipient email address'}
                type="email"
                id="recipient"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <label htmlFor="send-to-admin">
                <Form.Check.Input
                  type="checkbox"
                  id="send-to-admin"
                  checked={sendToAdmin}
                  onChange={(e: any) => setSendToAdmin(e.target.checked)}
                />
                <span className="d-inline-block ms-2">
                  Send the ticket to the admin as well.
                </span>
              </label>
            </Form.Group>
            <Flex alignItems="center" className="justify-content-end gap-2">
              <Button variant="light" onClick={closeModal} disabled={loading}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={loading}
                onClick={() => {}}
              >
                {loading ? 'Sending...' : 'Send'}
              </Button>
            </Flex>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BookingSendTicketModal
