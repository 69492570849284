import { useSelector } from 'react-redux'
import { app } from 'config'

export const useAuth = () => {
  const permissions: string[] = useSelector(
    (state: any) => state.auth.user?.permissions || []
  )

  /**
   * Clear the access token from the local storage.
   */
  const clearAccessToken = () => {
    delete localStorage[app.accessTokenKey]
  }

  /**
   * Set the access token in the local storage.
   */
  const setAccessToken = (token: string) => {
    localStorage[app.accessTokenKey] = token
  }

  /**
   * Check if the user has a specific permission.
   */
  const userCan = (
    permission: string,
    forcedPermissions: undefined | string[] = undefined
  ) => (forcedPermissions ?? permissions).includes(permission)

  return {
    clearAccessToken,
    setAccessToken,
    userCan,
  }
}

export default useAuth
