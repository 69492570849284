import { Card } from 'react-bootstrap'
import dayjs from 'dayjs'

import useUtils from 'hooks/useUtils'

interface BookingOverviewProps {
  booking: any;
  className: string;
}

function BookingOverview({ booking, className }: BookingOverviewProps) {
  const { formatHundredthHours } = useUtils()

  return (
    <Card className={className}>
      <Card.Body>
        <p className="fw-bold text-900">Booking Overview</p>
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-3">
          <div>
            <small>Ship:</small>
            <p className="fw-bold text-800">{booking.ship_name}</p>
          </div>
          <div>
            <small>Embark:</small>
            <p className="fw-bold text-800">{booking.sailing_port_name}</p>
          </div>
          <div>
            <small>Disembark:</small>
            <p className="fw-bold text-800">{booking.return_port_name}</p>
          </div>
          <div>
            <small>Area:</small>
            <p className="fw-bold text-800">
              {booking?.demog_area_full || '-'}
            </p>
          </div>
          <div>
            <small>Departure-Arrival:</small>
            <p className="fw-bold text-800">
              {dayjs(booking.sailing_date).format('DD MMM YYYY')}{' '}
              {formatHundredthHours(booking.sailing_time)}
              <br />
              {dayjs(booking.return_date).format('DD MMM YYYY')}{' '}
              {formatHundredthHours(booking.return_time)}
            </p>
          </div>
          <div>
            <small>Duration:</small>
            <p className="fw-bold text-800">{booking.cruise_length} Nights</p>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default BookingOverview
