import React from 'react'
import PropTypes from 'prop-types'
import WizardLayout from './WizardLayout'
import ContactWizardProvider from './ContactWizardProvider'

const Wizard = ({ variant, validation, progressBar, initialContactData = null, fetchContactList = null }) => {
  return (
    <ContactWizardProvider>
      <WizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
        initialContactData={initialContactData}
        fetchContactList={fetchContactList}
      />
    </ContactWizardProvider>
  )
}

Wizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
}

export default Wizard
