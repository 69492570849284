import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/itineraries`

/**
 * Fetches the itinerary segments for a given cruise ID.
 */
export const getSegmentsByCruiseId = async (cruiseId: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/cruise/${cruiseId}/segments`,
      method: 'GET',
    },
  })

const itineraryService = {
  getSegmentsByCruiseId,
}

export default itineraryService
