import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import * as echarts from 'echarts/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import cn from 'classnames'

import useChart from 'hooks/useChart'
import useReport from 'hooks/useReport'

import reportService from 'services/report.service'

interface ReportBookingsPerShipProps {
  className?: string;
}

function ReportBookingsPerShip({ className }: ReportBookingsPerShipProps) {
  const { makePieChartOptions } = useChart()
  const { currentYear, yearOptions } = useReport()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    getResults(currentYear)
  }, [])

  /**
   * Get results
   */
  const getResults = async (year: number) => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getBookingsPerShip({ year })

    setResults(data)

    setChartData(
      data.map((result: any) => ({
        name: result.ship_name,
        value: result.total_bookings,
      }))
    )

    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Bookings Per Ship</p>
          <Form.Group className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon="filter" className="me-2" />
            <Form.Select
              defaultValue={currentYear}
              onChange={(e: any) => getResults(e.target.value)}
              disabled={loading}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <hr />
        <div className="row g-5">
          <div className="col-lg-5">
            <Table responsive striped hover>
              <thead>
                <tr className="text-center">
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Ship Name</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Total Bookings</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={2} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !results.length && (
                    <td colSpan={2} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {results.map((result: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td>{result.ship_name}</td>
                    <td>{result.total_bookings}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-lg-7">
            <ReactEChartsCore
              echarts={echarts}
              option={makePieChartOptions(chartData)}
              style={{ height: '18.75rem' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ReportBookingsPerShip
