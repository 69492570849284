import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import cn from 'classnames'

import useUtils from 'hooks/useUtils'
import reportService from 'services/report.service'
import useReport from 'hooks/useReport'
import dayjs from 'dayjs'
import AppClipboard from 'components/common/AppClipboard'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useBooking from 'hooks/useBooking'

interface ReportDueBalancesByMonthProps {
  className?: string;
}

function ReportDueBalancesByMonth({
  className,
}: ReportDueBalancesByMonthProps) {
  const { monify, formatDate } = useUtils()

  const { currentYear, monthOptions, yearOptions } = useReport()
  const { formatViewRoute } = useBooking()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [bump, setBump] = useState(false)

  // We add 1 to month because dayjs month starts at 0
  const currentMonth = useMemo(() => dayjs().month() + 1, [])
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)

  const [selectedYear, setSelectedYear] = useState(currentYear)

  const dueBalanceDate = useSelector(
    (state: any) => state.report.dueBalanceDate
  )

  // Get results on initial load, also when selected month or year changes
  useEffect(() => {
    getResults()
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    const { month, year } = dueBalanceDate

    if (month && year) {
      setSelectedMonth(month)
      setSelectedYear(year)

      setBump(true)
      setTimeout(() => {
        setBump(false)
      }, 300)
    }
  }, [dueBalanceDate])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getDueBalancesByMonth({
      month: selectedMonth,
      year: selectedYear,
    })

    setResults(data)
    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body className={cn({ bump })}>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Due Balances By Month</p>
          <div className="d-flex flex-between-center gap-3">
            <Form.Group className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon="filter" className="me-2" />
              <Form.Select
                value={selectedMonth}
                onChange={(e: any) => setSelectedMonth(e.target.value)}
                disabled={loading}
              >
                {monthOptions.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center gap-2">
              <Form.Select
                value={selectedYear}
                onChange={(e: any) => setSelectedYear(e.target.value)}
                disabled={loading}
              >
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <hr />
        <Table responsive striped hover>
          <thead>
            <tr className="text-center">
              <th scope="col">
                <h6 className="text-700 fw-semibold">Ref</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Payment Date</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Balance</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={3} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !results.length && (
                <td colSpan={3} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {results.map((result: any, index: number) => (
              <tr key={index} className="text-center">
                <td className="fw-semibold">
                  <Link to={formatViewRoute(result.id)}>{result.ref_id}</Link>
                  <AppClipboard text={result.ref_id} />
                </td>
                <td>{formatDate(result.final_payment_date)}</td>
                <td>{monify(result.ledger?.balance)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default ReportDueBalancesByMonth
