import Gravatar from 'react-gravatar'
import { toast } from 'react-toastify'
import { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row, Form } from 'react-bootstrap'

import classNames from 'classnames'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import PropTypes from 'prop-types'

import { app } from 'config'
import cornerBg from 'assets/img/illustrations/corner-2.png'
import Background from 'components/common/Background'
import Flex from 'components/common/Flex'
import IconButton from 'components/common/IconButton'
import SubtleBadge from 'components/common/SubtleBadge'
import AppClipboard from 'components/common/AppClipboard'

import useUtils from 'hooks/useUtils'
import contactService from 'services/contact.service.ts'

dayjs.extend(relativeTime)

const InfoItem = ({ title, content, className }) => {
  return (
    <div className={classNames(className, 'mb-4')}>
      <h6
        className={classNames({
          'mb-1': title === 'Email' || title === 'Phone Number',
        })}
      >
        {title}
      </h6>
      {title === 'Email' && (
        <div className="text-nowrap d-flex align-items-center">
          <a className="fs-10" href={`mailto:${content}`}>
            {content}
          </a>
          <AppClipboard text={content} />
        </div>
      )}
      {title === 'Phone Number' && (
        <div className="text-nowrap d-flex align-items-center">
          <a className="fs-10" href={`tel:${content.replace(/\s+/g, '')}`}>
            {content}
          </a>
          <AppClipboard text={content} />
        </div>
      )}
      {title !== 'Email' && title !== 'Phone Number' && (
        <p className="mb-0 text-700 fs-10" dangerouslySetInnerHTML={{ __html: content }} />
      )}
    </div>
  )
}

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const ContactProfile = ({ contact }) => {
  const { formatPhoneNumber } = useUtils()
  const [newsletterSubbed, setNewsletterSubbed] = useState(false)

  useEffect(() => {
    if (contact) {
      fetchContactIsActive()
    }
  }, [contact])

  const fetchContactIsActive = async () => {
    const { data, error } = await contactService.subscribedToNewsletter(contact.id)

    if (error) {
      return // do nothing here
    }
    setNewsletterSubbed(data)
  }

  const subscribeToNewsletter = async () => {
    const { data, error } = await contactService.subscribeToNewsletter(contact.id)

    if (error) {
      toast(error.message || 'Unexpected Error')
      return
    }
    toast('Contact Subscribed!')
    setNewsletterSubbed(data)
  }

  const unsubscribeFromNewsletter = async () => {
    const { error } = await contactService.unsubscribeFromNewsletter(contact.id)

    if (error) {
      toast(error.message || 'Unexpected Error')
      return
    }
    toast('Contact Unsubscribed')
    setNewsletterSubbed(false)
  }

  /**
   * Compile the full address into an array
   */
  const fullAddress = useMemo(() => {
    return [
      contact.address_1,
      contact.address_2,
      contact.city,
      contact.county,
      contact.postcode,
    ].filter((value) => !!value)
      .join('<br />')
  }, [contact])

  const registeredAt = dayjs(contact.created_at).format('D MMM YYYY')
  const timeAgo = dayjs(contact.created_at).fromNow()

  /**
   * Text we display for the booking agreement
   */
  const bookingAgreementText = useMemo(() => {
    const agreed_at = contact.booking_conditions_agreed_at
    return agreed_at ? dayjs(agreed_at).format('D MMM YYYY') : 'Not yet'
  }, [contact.booking_conditions_agreed_at])

  /**
   * Text we display for the last login
   */
  const lastLoginText = useMemo(() => {
    const { last_login_at, last_login_ip } = contact.user || {}

    let text = ''

    // if we have a last login date, format it
    if (last_login_at) {
      text = dayjs(last_login_at).format('D MMM YYYY')

      // if we have an IP, add it to the text
      if (last_login_ip) {
        text += ` from ${last_login_ip}`
      }
    }

    return text

  }, [contact.user])

  return (
    <Card className="mb-xl-3">
      <Card.Body className="bg-body-tertiary">
        <Card className="border rounded-3 bg-white dark__bg-1000 shadow-none">
          <Background
            image={cornerBg}
            className="bg-card d-none d-sm-block d-xl-none"
          />
          <Card.Body
            className="row g-0 flex-column flex-sm-row flex-xl-column z-1 align-items-center">
            <Col xs="auto" className="text-center me-sm-x1 me-xl-0">
              <Gravatar email={contact.email} size={100} className="rounded-circle" />
            </Col>

            <Col xs="auto" className="text-center my-2">
              <p className="fw-bold mb-0">
                {app.contactPrefix}
                {contact.id}
              </p>
            </Col>

            <Col sm={8} md={6} lg={4} xl={12} className="ps-sm-1 ps-xl-0">
              <span
                className="fw-semibold mb-0 text-800 text-center text-sm-start text-xl-center mb-3 mt-3 mt-sm-0 mt-xl-3">
                <span
                  className="me-sm-x1">{contact.first_name} {contact.last_name}</span>
                <SubtleBadge pill bg={contact.status === 'active'
                  ? 'success'
                  : 'danger'}
                  className="me-2 fs-11 mb-2">
                  {contact.status}
                </SubtleBadge>
              </span>

              <Flex justifyContent="center" className="gap-2">
                <IconButton
                  variant="primary"
                  icon="plus"
                  transform="shrink-3 down-1"
                  size="sm"
                  className="w-50 px-2 text-nowrap"
                >
                  <span className="fs-11">New Ticket</span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  icon="phone-alt"
                  transform="shrink-4"
                  size="sm"
                  className="w-50"
                >
                  <span className="fs-11">Call</span>
                </IconButton>
              </Flex>

              <Flex justifyContent="center" alignItems="center"
                className="mt-3 flex-column c">
                <h6
                  className="mb-1 mb-0 text-600 fs-10"
                >
                  {registeredAt}
                </h6>
                <h6
                  className="mb-1 mb-0 text-500 fs-10"
                >
                  {`(${timeAgo})`}
                </h6>
              </Flex>
            </Col>
          </Card.Body>
        </Card>

        <Row
          className="border rounded-3 p-x1 mt-3 bg-white dark__bg-1000 mx-0 g-0">
          <Col md={6} xl={12} className="pe-md-4 pe-xl-0">
            <InfoItem title="Email" content={contact.email} />
            <InfoItem title="Phone Number" content={formatPhoneNumber(contact.phone)} />
            <InfoItem
              title="Address"
              content={fullAddress}
            />
            <InfoItem title="Booking Conditions Agreed" content={bookingAgreementText} />
            <InfoItem title="Last Login" content={lastLoginText} />
          </Col>
        </Row>
        <Row
          className="border rounded-3 p-x1 mt-2 bg-white dark__bg-1000 mx-0 g-0">
          <Col md={6} xl={12} className="pe-md-4 pe-xl-0">
            <div className="ps-2">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Customer Newsletter"
                className="form-label-nogutter"
                name="numberVisibility"
                onChange={newsletterSubbed ? unsubscribeFromNewsletter : subscribeToNewsletter}
                checked={newsletterSubbed}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ContactProfile
