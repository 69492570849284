import React from 'react'
import { Spinner } from 'react-bootstrap'

const spinner = ({className = 'text-center'}) => {
  return (
    <div className={className}>
      <Spinner animation="border" role="status" className="m-2">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>

  )
}

export default spinner
