import { configureStore, Store } from '@reduxjs/toolkit'

import appReducer from 'store/slices/appSlice'
import authResucer from 'store/slices/authSlice'
import ledgerReducer from 'store/slices/ledgerSlice'
import reportReducer from 'store/slices/reportSlice'

const store: Store = configureStore({
  reducer: {
    app: appReducer,
    auth: authResucer,
    ledger: ledgerReducer,
    report: reportReducer,
  },
})

export default store
