import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { App } from 'app'

import Main from 'Main'
import store from 'store/store'

import 'helpers/initFA'

const container = document.getElementById('main') as HTMLElement

const root = createRoot(container)

root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <Main>
          <App />
        </Main>
      </BrowserRouter>
    </Provider>
  </>
)
