import { app } from 'config'
import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import classNames from 'classnames'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import AppClipboard from 'components/common/AppClipboard'
import Flex from 'components/common/Flex'
import SubtleBadge from 'components/common/SubtleBadge'

const PrioritySelect = ({ title, color, data, className, style }) => {
  return (
    <div
      style={style}
      className={classNames('d-flex align-items-center gap-2', className)}
    >
      <div style={{ '--falcon-circle-progress-bar': data }}>
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={color}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{title}</h6>
    </div>
  )
}

PrioritySelect.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const AgentSelect = ({ agent, className, style }) => {
  return (
    <Form.Select
      style={style}
      className={className}
      size="sm"
      defaultValue={agent}
    >
      {['Select Agent', 'Anindya', 'Nowrin', 'Khalid'].map(item => (
        <option key={item}>{item}</option>
      ))}
    </Form.Select>
  )
}

AgentSelect.propTypes = {
  agent: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export const columns = [
  {
    accessor: 'id',
    Header: 'ID',
    headerProps: { style: { minWidth: '2rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4',
    },
    Cell: rowData => {
      const { id } = rowData.row.original
      return (
        <Flex alignItems="center" className="position-relative py-1">
          <h6 className="mb-0">
            <Link
              to={`/simplycruises/contacts/${id}`}
              className="stretched-link text-900"
            >
              {app.contactPrefix}{id}
            </Link>
          </h6>
        </Flex>
      )
    },
  },
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
    cellProps: {
      className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2',
    },
    Cell: rowData => {
      const { id, first_name, last_name } = rowData.row.original

      return (
        <Flex alignItems="center" className="position-relative py-1">
          {/*{avatar.img ? (*/}
          {/*  <Avatar src={avatar.img} size="xl" className="me-2" />*/}
          {/*) : (*/}
          {/*  <Avatar size="xl" name={avatar.name} className="me-2" />*/}
          {/*)}*/}
          <h6 className="mb-0">
            <Link
              to={`/simplycruises/contacts/${id}`}
              className="stretched-link text-900"
            >
              {first_name} {last_name}
            </Link>
          </h6>
        </Flex>
      )
    },
  },
  {
    accessor: 'email',
    Header: 'Email',
    headerProps: { style: { minWidth: '12rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4',
    },
    Cell: rowData => {
      const { email } = rowData.row.original
      return (
        <div className="text-nowrap">
          <Link to={`mailto:${email}`} className="fw-semibold">
            {email}
          </Link>
          <AppClipboard text={email} />
        </div>
      )
    },
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    headerProps: { style: { minWidth: '9rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4',
    },
    Cell: rowData => {
      const { phone } = rowData.row.original
      return (
        <div className="text-nowrap">
          <h6 className="mb-0 text-700">
            {phone}
            <AppClipboard text={phone} />
          </h6>
        </div>
      )
    },
  },
  // {
  //   accessor: 'bookings',
  //   Header: 'Bookings',
  //   headerProps: { style: { minWidth: '6rem' }, className: 'text-900' },
  //   cellProps: {
  //     className: 'py-2 pe-4',
  //   },
  //   Cell: rowData => {
  //
  //     return (
  //       <h6 className="mb-0 text-700">
  //         #
  //       </h6>
  //     )
  //   },
  // },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: { style: { minWidth: '5rem' }, className: 'text-900' },
    cellProps: {
      className: 'fs-9 pe-4',
    },
    Cell: rowData => {
      const { status } = rowData.row.original
      let type = 'danger'
      if (status === 'active') {
        type = 'success'
      }
      return (
        <SubtleBadge bg={type} className="me-2">
          {status}
        </SubtleBadge>
      )
    },
  },
  {
    accessor: 'registered',
    Header: 'Registered',
    headerProps: { style: { minWidth: '8rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4',
    },
    Cell: rowData => {
      const { created_at } = rowData.row.original
      // Aug 16, 2018 8:02 PM
      const datetime = dayjs(created_at).format('D MMM YYYY')
      return (
        <h6 className="mb-0 text-700">{datetime}</h6>
      )
    },
  },
  {
    accessor: 'actions',
    Header: 'Actions',
    headerProps: { style: { minWidth: '8rem' }, className: 'text-900' },
    cellProps: {
      className: 'py-2 pe-4',
    },
    Cell: rowData => {
      const { id } = rowData.row.original

      return (
        <Button
          as={Link}
          color="primary"
          size="sm"
          className="m-1"
          to={`/simplycruises/contacts/${id}`}
        >
          View
        </Button>
      )
    },
  },
  // {
  //   "created_at": "2024-02-05T18:39:29.000000Z",
  //   "updated_at": "2024-02-05T18:39:30.000000Z"
  // },
  // {
  //   accessor: 'priority',
  //   Header: 'Priority',
  //   headerProps: { className: 'text-900' },
  //   cellProps: {
  //     className: 'pe-4',
  //   },
  //   Cell: rowData => {
  //     const { priority } = rowData.row.original
  //     return (
  //       <PrioritySelect
  //         title={priority.title}
  //         color={priority.color}
  //         data={priority.data}
  //       />
  //     )
  //   },
  // },
  // {
  //   accessor: 'agent',
  //   Header: 'Agent',
  //   headerProps: { className: 'text-end text-900' },
  //   Cell: rowData => {
  //     const { agent } = rowData.row.original
  //     return <AgentSelect agent={agent} className="w-auto ms-auto" />
  //   },
  // },
]

// export const CardLayout = ({ data, isSelectedItem, toggleSelectedItem }) => {
//   return (
//     <div className="d-flex flex-column gap-3">
//       {data.slice(0, 12).map((ticket, index) => (
//         <div
//           key={index}
//           className="bg-white dark__bg-1100 d-md-flex d-xl-inline-block d-xxl-flex align-items-center p-x1 rounded-3 shadow-sm card-view-height w-100"
//         >
//           <div className="d-flex align-items-start align-items-sm-center">
//             <Form.Check
//               type="checkbox"
//               id="inboxBulkSelect"
//               className="fs-9 form-check me-2 me-xxl-3 mb-0"
//             >
//               <Form.Check.Input
//                 type="checkbox"
//                 checked={isSelectedItem(ticket.id)}
//                 onChange={() => toggleSelectedItem(ticket.id)}
//               />
//             </Form.Check>
//             <Link
//               to="/support-desk/contact-details"
//               className="d-none d-sm-block"
//             >
//               {ticket.avatar.img ? (
//                 <Avatar src={ticket.avatar.img} size="3xl" />
//               ) : (
//                 <Avatar size="3xl" name={ticket.avatar.name} />
//               )}
//             </Link>
//             <div className="ms-1 ms-sm-3">
//               <p className="fw-semibold mb-3 mb-sm-2">
//                 <Link to="/support-desk/tickets-preview">{ticket.subject}</Link>
//               </p>
//               <Row className="align-items-center gx-0 gy-2">
//                 <Col xs="auto" className="me-2">
//                   <h6 className="mb-0">
//                     <Link
//                       to="/support-desk/contact-details"
//                       className="text-800 d-flex align-items-center gap-1"
//                     >
//                       <FontAwesomeIcon icon="user" transform="shrink-3 up-1" />
//                       <span>{ticket.name}</span>
//                     </Link>
//                   </h6>
//                 </Col>
//                 <Col xs="auto" className="lh-1 me-3">
//                   <SubtleBadge bg={ticket.status.type}>
//                     {ticket.status.content}
//                   </SubtleBadge>
//                 </Col>
//                 <Col xs="auto">
//                   <h6 className="mb-0 text-500">{ticket.date}</h6>
//                 </Col>
//               </Row>
//             </div>
//           </div>
//           <div className="border-bottom mt-4 mb-x1"></div>
//           <div className="d-flex justify-content-between ms-auto">
//             <PrioritySelect
//               title={ticket.priority.title}
//               color={ticket.priority.color}
//               data={ticket.priority.data}
//               className="ms-md-4 ms-xl-0"
//               style={{ width: '7.5rem' }}
//             />
//             <AgentSelect agent={ticket.agent} style={{ width: '9.375rem' }} />
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }
//
// CardLayout.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.object),
//   isSelectedItem: PropTypes.func,
//   toggleSelectedItem: PropTypes.func,
// }
