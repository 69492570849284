import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import WizardInput from './WizardInput'
import PostcodeLookupComponent from './PostcodeLookupComponent'
import Divider from 'components/common/Divider'

const PersonalForm = ({ register, errors, setValue }) => {

  const handleAddressLookup = address => {
    setValue('address_1', address.line_1)
    setValue('address_2', address.line_2)
    setValue('postcode', address.postcode)
    setValue('country', address.country)
    setValue('county', address.county)
    setValue('city', address.post_town)
    console.log(address)
  }
  return (
    <>
      <WizardInput
        type="number"
        label="Phone"
        name="phone"
        errors={errors}
        formGroupProps={{
          className: 'mb-3',
        }}
        formControlProps={{
          className: 'input-spin-none',
          ...register('phone', {
            required: 'Phone is required',
          }),
        }}
      />

      <PostcodeLookupComponent
        onAddressSelected={handleAddressLookup}
      />

      <Divider/>

      <WizardInput
        label="Address"
        name="address_1"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('address_1', {
            required: 'Address is required',
          }),
        }}
      />
      <WizardInput
        label="Address 2"
        name="address_2"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('address_2'),
        }}
      />
      <WizardInput
        label="City"
        name="city"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('city'),
        }}
      />
      <WizardInput
        label="County"
        name="county"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('county'),
        }}
      />
      <WizardInput
        label="Country"
        name="country"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('country'),
        }}
      />
      <WizardInput
        label="Postcode"
        name="postcode"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('postcode'),
        }}
      />
    </>
  )
}

PersonalForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
}

export default PersonalForm
