import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { faUndo } from '@fortawesome/free-solid-svg-icons'

import cn from 'classnames'
import dayjs from 'dayjs'

import { app } from 'config'
import { toggleTransactionModal } from 'store/slices/ledgerSlice'
import useUtils from 'hooks/useUtils'

import ConfirmationModal from 'components/common/ConfirmationModal'
import SubtleBadge from 'components/common/SubtleBadge'
import LedgerFileRefModal from 'modules/simple-crm/components/ledgers/LedgerFileRefModal'
import LedgerTransactionModal from 'modules/simple-crm/components/ledgers/LedgerTransactionModal'
import LedgerSettlerModal from 'modules/simple-crm/components/ledgers/LedgerSettlerModal'

import bookingService from 'services/booking.service'
import ledgerService from 'services/ledger.service'
import ledgerTransactionService from 'services/ledger-transaction.service'

import 'modules/simple-crm/assets/scss/ledger.scss'

interface LedgerSheetProps {
  bookingId: string | number;
  className?: string;
  onUpdate?: Function;
}

const { ledgerStatus }: any = app

function LedgerSheet({
  className,
  bookingId,
  onUpdate = () => {},
}: LedgerSheetProps) {
  const { monify, formatDate } = useUtils()

  const dispatch = useDispatch()

  const [ledger, setLedger] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [showFileRefModal, setShowFileRefModal] = useState(false)
  const [transaction, setTransaction] = useState<any>(null)
  const [showConfirmation, setShowConfirmation] = useState<any>(null)
  const [showSettlerForm, setShowSettlerForm] = useState<boolean>(false)
  const [showUnsettleConfirm, setShowUnsettleConfirm] =
    useState<boolean>(false)

  const showTransactionModal: boolean = useSelector(
    (state: any) => state.ledger.showTransactionModal
  )

  // When the component mounts...
  useEffect(() => {
    getLedger()
  }, [])

  /**
   * Fetch booking ledger.
   */
  const getLedger = async () => {
    setLoading(true)

    const { data }: any = await bookingService.getLedger(bookingId)

    // Set the ledger
    setLedger({
      ...data,
      transactions: withBalances(data?.transactions || []),
    })

    setLoading(false)
  }

  /**
   * Returns the transactions with balances.
   */
  const withBalances = (transactions: any[]) => {
    let currentBalance = 0

    return transactions.map((t: any) => {
      t.balance = currentBalance += +t.amount
      return t
    })
  }

  /**
   * Handles the event when the file reference is updated.
   */
  const onFileRefUpdate = () => {
    setShowFileRefModal(false)
    getLedger()
  }

  /**
   * Handles the transaction deletion confirmation.
   */
  const handleConfirm = async () => {
    // Show the confirmation modal
    setShowConfirmation(false)

    // Start the loading state so that while the transaction is being deleted so the user knows something is happening
    setLoading(true)

    await ledgerTransactionService.remove(transaction.id)

    toast('Transaction deleted successfully.')

    // Clear the transaction state
    setTransaction(null)

    // Trigger transaction update event handler
    handleTransactionUpdate()
  }

  /**
   * Handles the event when the unsettling is confirmed.
   */
  const unsettle = async () => {
    setLoading(true)

    await ledgerService.unsettle(ledger.id)

    toast('Ledger marked as oustanding.')
    getLedger()

    setShowUnsettleConfirm(false)
  }

  /**
   * Handles the event when the delete button is clicked.
   */
  const handleDeleteClick = (transaction: any) => {
    // Set the transaction to be deleted
    setTransaction(transaction)

    // Show the confirmation modal
    setShowConfirmation(true)
  }

  /**
   * Handles the event when the edit button is clicked.
   */
  const showTransactionForm = (transaction: any) => {
    // Set the transaction to be edited
    setTransaction(transaction)

    // Show the transaction modal
    dispatch(toggleTransactionModal(true))
  }

  /**
   * Handles the event when the transaction modal is closed.
   */
  const handleTransactionModalClose = () => {
    // Clear the transaction state
    setTransaction(null)

    // Close the transaction modal
    dispatch(toggleTransactionModal(false))
  }

  /**
   * Handles the event when a transaction is updated.
   * Refetches the ledger and triggers the parent's onUpdate event handler.
   */
  const handleTransactionUpdate = () => {
    getLedger()
    onUpdate()
  }

  /**
   * Handles the event when the ledger is settled.
   */
  const handleSettled = async () => {
    setShowSettlerForm(false)
    getLedger()
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-sm-end align-items-sm-center mb-3">
        <div className="d-flex flex-column-reverse flex-sm-row align-items-sm-center gap-1">
          <p className="fw-bold text-900 m-0">Ledger</p>
          {ledger && (
            <SubtleBadge pill bg={ledgerStatus[ledger?.status].variant}>
              {ledgerStatus[ledger?.status].label}
              {ledger.status === ledgerStatus.settled.key &&
                `: ${formatDate(ledger.settled_at)}`}
            </SubtleBadge>
          )}
        </div>
        <div className="d-flex align-items-end align-items-sm-center gap-1">
          <Button
            variant="info"
            size="sm"
            onClick={() => showTransactionForm(null)}
          >
            <FontAwesomeIcon icon="plus" />
            <span className="d-none d-xl-inline-block ms-2">Transaction</span>
          </Button>
          {ledger?.status === ledgerStatus.settled.key ? (
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setShowUnsettleConfirm(true)}
            >
              <FontAwesomeIcon icon={faUndo} />
              <span className="d-none d-xl-inline-block ms-2">Unsettle</span>
            </Button>
          ) : (
            <Button
              variant="success"
              size="sm"
              onClick={() => setShowSettlerForm(true)}
            >
              <FontAwesomeIcon icon="check" />
              <span className="d-none d-xl-inline-block ms-2">Settle</span>
            </Button>
          )}
        </div>
      </div>
      <Table
        className={cn(className, 'app-table-striped')}
        responsive
        striped
        hover
      >
        <thead>
          <tr className="bg-info">
            <th scope="col">
              <h6 className="fs-9 text-white m-0 fw-bold">
                {ledger?.code || '-'}
              </h6>
            </th>
            <th scope="col" colSpan={7}>
              <h6 className="fs-9 text-white m-0">
                {!loading && ledger && (
                  <span
                    className="fw-bold cursor-pointer"
                    onClick={() => setShowFileRefModal(true)}
                  >
                    FILE: {ledger?.file_ref || 'NOT SET'}
                    <FontAwesomeIcon icon="edit" className="ms-2" />
                  </span>
                )}
              </h6>
            </th>
          </tr>
          <tr className="border-bottom">
            <th scope="col" className="text-nowrap">
              <h6 className="text-800 m-0 fw-bold">ID</h6>
            </th>
            <th scope="col" className="text-nowrap">
              <h6 className="text-800 m-0 fw-bold">Date</h6>
            </th>
            <th scope="col" className="text-nowrap">
              <h6 className="text-800 m-0 fw-bold">Type</h6>
            </th>
            <th scope="col" className="text-nowrap">
              <h6 className="text-800 m-0 fw-bold">Description</h6>
            </th>
            <th scope="col" className="text-nowrap">
              <h6 className="text-800 m-0 fw-bold">Charge</h6>
            </th>
            <th scope="col" className="text-nowrap">
              <h6 className="text-800 m-0 fw-bold">Payment</h6>
            </th>
            <th scope="col" className="text-nowrap cell-balance">
              <h6 className="text-800 m-0 fw-bold text-center">Balance</h6>
            </th>
            <th scope="col" className="text-nowrap">
              <h6 className="text-800 m-0 fw-bold">Action</h6>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={8} className="text-center fs-10">
                Fetching transactions...
              </td>
            </tr>
          ) : (
            <>
              {!ledger?.transactions?.length ? (
                <tr>
                  <td colSpan={8} className="text-center fs-10">
                    No transactions yet.
                  </td>
                </tr>
              ) : (
                ledger?.transactions?.map((transaction: any, index: number) => (
                  <tr key={`transaction-${transaction.id}`}>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      <SubtleBadge pill className="fs-10 bg-600 text-white">
                        {transaction.code}
                      </SubtleBadge>
                    </td>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      {dayjs(transaction.date).format('MMM DD, YYYY')}
                    </td>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      {transaction.type}
                    </td>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      {transaction.description}
                    </td>
                    <td className="fs-10 fw-bold text-nowrap">
                      {transaction.amount >= 0 ? (
                        <span className="text-danger">
                          {monify(transaction.amount)}
                        </span>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className="fs-10 fw-bold text-nowrap">
                      {transaction.amount < 0 ? (
                        <span className="text-success-emphasis">
                          {monify(Math.abs(transaction.amount))}
                        </span>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className="fs-10 text-900 fw-bold text-nowrap cell-balance text-center">
                      {monify(transaction.balance)}
                    </td>
                    <td className="fs-10 text-800 fw-semibold text-nowrap">
                      <div className="d-flex gap-2">
                        <Button
                          variant="falcon-default"
                          size="sm"
                          onClick={() => showTransactionForm(transaction)}
                        >
                          <FontAwesomeIcon icon="edit" className="fs-10" />
                        </Button>
                        <Button
                          variant="falcon-danger"
                          size="sm"
                          onClick={() => handleDeleteClick(transaction)}
                        >
                          <FontAwesomeIcon icon="trash" className="fs-10" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </>
          )}
        </tbody>
        {ledger && (
          <tfoot>
            <tr className="bg-warning fw-bold fs-9">
              <td colSpan={2} className="text-800 text-nowrap">
                <span className="text-white">CURRENT BALANCE:</span>
              </td>
              <td colSpan={4}>&nbsp;</td>
              <td className="text-800 text-nowrap text-center">
                <span className="text-white">{monify(ledger.balance)}</span>
              </td>
              <td>&nbsp;</td>
            </tr>
          </tfoot>
        )}
      </Table>
      <LedgerFileRefModal
        show={showFileRefModal}
        ledger={ledger}
        onSuccess={onFileRefUpdate}
        onClose={() => setShowFileRefModal(false)}
      />
      <LedgerTransactionModal
        ledgerId={ledger?.id}
        transaction={transaction}
        show={showTransactionModal}
        onClose={handleTransactionModalClose}
        onSuccess={handleTransactionUpdate}
      />
      <ConfirmationModal
        show={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleConfirm={handleConfirm}
        confirmationText="Do you really want to delete this transaction?"
        confirmButtonText="Delete"
      />
      <ConfirmationModal
        show={showUnsettleConfirm}
        handleClose={() => setShowUnsettleConfirm(false)}
        handleConfirm={unsettle}
        confirmationText={`This will mark this ledger as "Outstanding". Do you want to proceed?`}
        confirmButtonText="Unsettle"
      />
      <LedgerSettlerModal
        ledgerId={ledger?.id}
        show={showSettlerForm}
        onClose={() => setShowSettlerForm(false)}
        onSuccess={handleSettled}
      />
    </>
  )
}

export default LedgerSheet
