import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/ledgers`

/**
 * Updates a Ledger's file reference.
 */
export const updateFileRef = async (id: string | number, fileRef: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}/file-ref`,
      method: 'PUT',
      data: { file_ref: fileRef },
    },
  })

/**
 * Checks if a Ledger's file reference is already in use.
 */
export const validateFileRef = async (id: string | number, fileRef: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}/file-ref/${fileRef}/validate`,
      method: 'GET',
    },
  })

/**
 * Settle a Ledger.
 */
export const settle = async (id: string | number, settled_at: string) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}/settle`,
      method: 'PUT',
      data: { settled_at },
    },
  })

/**
 * Unsettle a Ledger.
 */
export const unsettle = async (id: string | number) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}/unsettle`,
      method: 'DELETE',
    },
  })

const ledgerService = {
  updateFileRef,
  validateFileRef,
  settle,
  unsettle,
}

export default ledgerService
