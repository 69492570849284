/* eslint-disable react/prop-types */
import React from 'react'
import Flex from '../Flex'
import ReactPaginate from 'react-paginate'

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage,
}) => {
  return (
    <Flex alignItems="center" justifyContent="center">

      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(e) => gotoPage(e.selected)}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={1}
      />
    </Flex>
  )
}

export default AdvanceTablePagination
