import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/contacts`

/**
 * Get all contacts
 */
export const getContactList = async (page: number, globalFilter: string) => {
  const search = globalFilter || ''

  return callExternalApi({
    config: {
      url: `${endpoint}?page=${page}&search=${search}`,
      method: 'GET',
    },
  })
}

/**
 * Get a single contact data
 */
export const getContact = async (id: number) => {
  return callExternalApi({
    config: {
      url: `${endpoint}/${id}`,
      method: 'GET',
    },
  })
}

/**
 * Create a new contact
 */
export const postContact = async (body: any) => {
  return callExternalApi({
    config: {
      url: endpoint,
      method: 'POST',
      data: body,
    },
  })
}

/**
 * Toggle active status of a contact
 */
export const toggleActive = async (id: number) => {
  return callExternalApi({
    config: {
      url: `${endpoint}/${id}/toggle-active`,
      method: 'POST',
    },
  })
}

/**
 * Check if a contact is subscribed to the newsletter
 */
export const subscribedToNewsletter = async (id: number) => {
  return callExternalApi({
    config: {
      url: `${endpoint}/${id}/is-subscribed`,
      method: 'GET',
    },
  })
}

/**
 * Subscribe a contact to the newsletter
 */
export const subscribeToNewsletter = async (id: number) => {
  return callExternalApi({
    config: {
      url: `${endpoint}/${id}/subscribe`,
      method: 'POST',
    },
  })
}

/**
 * Unsubscribe a contact from the newsletter
 */
export const unsubscribeFromNewsletter = async (id: number) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}/unsubscribe`,
      method: 'POST',
    },
  })

/**
 * Delete a contact
 */
export const deleteContact = async (id: number) =>
  callExternalApi({
    config: {
      url: `${endpoint}/${id}`,
      method: 'DELETE',
    },
  })

const contactService = {
  getContactList,
  getContact,
  postContact,
  toggleActive,
  subscribedToNewsletter,
  subscribeToNewsletter,
  unsubscribeFromNewsletter,
  deleteContact,
}

export default contactService
