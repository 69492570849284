import round from 'lodash.round'

export const useBooking = () => {
  /**
   * Generates the URL for the itinerary map image based on the provided itinerary code.
   *
   * @param itineraryCode - The code representing the itinerary.
   * @returns The URL of the itinerary map image.
   */
  const itineraryMapFromCode = (itineraryCode: string) =>
    `https://www.msccruises.com/images/msc/iti/itin/${itineraryCode}.jpg`

  /**
   * Generates the URL for the view booking route based on the provided booking ID.
   */
  const formatViewRoute = (id: string) => `/simplycruises/bookings/${id}`

  /**
   * Computes the profit for the booking.
   */
  const computeProfit = (booking: any) =>
    round(booking?.totals?.booking_value - booking.total_net_price, 2)

  return {
    computeProfit,
    formatViewRoute,
    itineraryMapFromCode,
  }
}

export default useBooking
