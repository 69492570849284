import { Card, Row, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import numeral from 'numeral'

import ReactEChartsCore from 'echarts-for-react/lib/core'
import { LineChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import * as echarts from 'echarts/core'

import { byThousands, camelize, getColor, rgbaColor } from 'helpers/utils'

import Spinner from 'components/common/Spinner'
import Flex from 'components/common/Flex'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
])

const tooltipFormatter = (params) => {
  return `
<div>
    <h6 class="fs-10 text-700 mb-0">
    <span class="dot me-1 d-inline-block" style="background-color:${
      params[0].borderColor ? params[0].borderColor : params[0].color
    }"></span>
      ${params[0].name} : ${byThousands(params[0].value)}
    </h6>
</div>
`
}

const getChartCode = (months, monthsData) => {
  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: getColor('gray-1100'),
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
    },
    xAxis: {
      type: 'category',
      data: months,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: (value) => value.substring(0, 4),
        margin: 15,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getColor('gray-200'),
        },
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15,
        formatter: function (value) {
          return numeral(value).format('0.[0]a')
        },
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0,
    },
    series: [
      {
        type: 'line',
        data: monthsData,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('primary'),
          borderWidth: 2,
        },
        lineStyle: {
          color: getColor('primary'),
        },
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        smooth: false,
        emphasis: {
          scale: true,
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.5),
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0),
              },
            ],
          },
        },
      },
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' },
  })

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '18.75rem' }}
    />
  )
}

const DashboardDataOverTime = ({ apiFn, title }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const { data, error } = await apiFn()

    if (error) {
      toast(error.message || 'Unexpected Error')
      return
    }
    setData(data)
  }

  if (!data) {
    return <Spinner />
  }

  // console.log(data)

  let months = Object.keys(data)
  let monthsData = Object.values(data)

  return (
    <Card className="shadow-none shadow-show-xl scrollbar">
      <Card.Header>
        <Row>
          <Col>
            <Flex>
              <h5
                className="mb-0 hover-actions-trigger text-truncate text-nowrap"
                id={camelize(title)}
              >
                {title}
              </h5>
            </Flex>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <div style={{ maxHeight: '25rem' }}>
          {getChartCode(months, monthsData)}
        </div>
      </Card.Body>
    </Card>
  )
}

export default DashboardDataOverTime
