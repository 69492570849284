import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Card } from 'react-bootstrap'

import ToolBookingSearch from 'modules/simple-crm/components/tools/ToolBookingSearch'
import ToolSBWithoutFileRef from 'modules/simple-crm/components/tools/ToolBookingsWithoutFileRef'
import ToolFileRefSearch from 'modules/simple-crm/components/tools/ToolFileRefSearch'
import ToolOption from 'modules/simple-crm/components/tools/ToolOption'
import ToolPassengerSearch from 'modules/simple-crm/components/tools/ToolPassengerSearch'
import ToolPassengerMembership from 'modules/simple-crm/components/tools/ToolPassengerMembership'

import 'modules/simple-crm/assets/scss/tools.scss'
import ToolBookingsOutstanding from 'modules/simple-crm/components/tools/ToolBookingsOutstanding'

const Tool = {
  BOOKING_SEARCH: 'booking-search',
  PASSENGER_SEARCH: 'passenger-search',
  PASSENGER_MEMBERSHIP: 'passenger-membership',
  FILE_REF_SEARCH: 'file-ref-search',
  BOOKING_NO_FILE_REF: 'bookings-with-missing-file-ref',
  BOOKING_OUTSTANDING: 'outstanding-bookings',
}

function ToolsPage() {
  const [showToolsList, setShowToolsList] = useState<boolean>(true)
  const [activeTitle, setActiveTitle] = useState<string>('Booking Search')
  const [activeTool, setActiveTool] = useState<string>(Tool.BOOKING_SEARCH)

  const handleToolSelect = (key: string, title: string) => {
    setActiveTool(key)
    setActiveTitle(title)
  }

  return (
    <div id="page-tools" className="d-flex gap-3">
      {showToolsList && (
        <div>
          <Card className="tools-list flex-shrink-0">
            <Card.Header className="bg-light fw-bold">Tools</Card.Header>
            <Card.Body className="py-0">
              <ul className="list-unstyled divide-y fs-10">
                <ToolOption
                  icon="search"
                  activeTool={activeTool}
                  toolKey={Tool.BOOKING_SEARCH}
                  title="Booking Search"
                  onClick={handleToolSelect}
                />
                <ToolOption
                  icon="search"
                  activeTool={activeTool}
                  toolKey={Tool.PASSENGER_SEARCH}
                  title="Passenger Search"
                  onClick={handleToolSelect}
                />
                <ToolOption
                  icon="search"
                  activeTool={activeTool}
                  toolKey={Tool.FILE_REF_SEARCH}
                  title="File Ref Search"
                  onClick={handleToolSelect}
                />
                <ToolOption
                  icon="search"
                  activeTool={activeTool}
                  toolKey={Tool.BOOKING_NO_FILE_REF}
                  title="Bookings Without File Ref"
                  onClick={handleToolSelect}
                />
                <ToolOption
                  icon="search"
                  activeTool={activeTool}
                  toolKey={Tool.PASSENGER_MEMBERSHIP}
                  title="Voyager Member Lookup"
                  onClick={handleToolSelect}
                />
                <ToolOption
                  icon="search"
                  activeTool={activeTool}
                  toolKey={Tool.BOOKING_OUTSTANDING}
                  title="Outstanding Bookings"
                  onClick={handleToolSelect}
                />
              </ul>
            </Card.Body>
          </Card>
        </div>
      )}
      <Card className="w-100">
        <Card.Header className="bg-light fw-bold">
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon
              icon={faBars}
              className="me-3 cursor-pointer"
              onClick={() => setShowToolsList(!showToolsList)}
            />
            <span>{activeTitle}</span>
          </div>
        </Card.Header>
        <Card.Body>
          {activeTool === Tool.BOOKING_SEARCH && <ToolBookingSearch />}
          {activeTool === Tool.PASSENGER_SEARCH && <ToolPassengerSearch />}
          {activeTool === Tool.FILE_REF_SEARCH && <ToolFileRefSearch />}
          {activeTool === Tool.BOOKING_NO_FILE_REF && <ToolSBWithoutFileRef />}
          {activeTool === Tool.PASSENGER_MEMBERSHIP && (
            <ToolPassengerMembership />
          )}
          {activeTool === Tool.BOOKING_OUTSTANDING && (
            <ToolBookingsOutstanding />
          )}
        </Card.Body>
      </Card>
    </div>
  )
}

export default ToolsPage
