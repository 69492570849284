import { useEffect, useState } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'

import cn from 'classnames'

import useUtils from 'hooks/useUtils'
import reportService from 'services/report.service'

interface ReportRevenuePerShipProps {
  className?: string;
}

function ReportRevenuePerShip({ className }: ReportRevenuePerShipProps) {
  const { monify } = useUtils()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  useEffect(() => {
    getResults()
  }, [])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getRevenuePerShip()

    setResults(data)
    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Revenue Per Ship</p>
        </div>
        <hr />
        <Table responsive striped hover>
          <thead>
            <tr className="text-center">
              <th scope="col">
                <h6 className="text-700 fw-semibold">Ship Name</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Total Bookings</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Total Revenue</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={3} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !results.length && (
                <td colSpan={3} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {results.map((result: any, index: number) => (
              <tr key={index} className="text-center">
                <td>{result.ship_name}</td>
                <td>{result.total_bookings}</td>
                <td>{monify(result.total_revenue)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default ReportRevenuePerShip
