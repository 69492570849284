import { useEffect, useState } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'
import * as echarts from 'echarts/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import cn from 'classnames'

import { app } from 'config'
import useChart from 'hooks/useChart'
import reportService from 'services/report.service'

type Props = {
  className?: string;
};

const { ledgerStatus }: any = app

function ReportBookingsLedgerStatus({ className }: Props) {
  const { makePieChartOptions } = useChart()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    getResults()
  }, [])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getLedgersStatus()

    setResults(data)

    setChartData(
      data.map((result: any) => ({
        name: ledgerStatus[result.status].label,
        value: result.count,
      }))
    )

    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Bookings Ledger Status</p>
        </div>
        <hr />
        <div className="row g-5">
          <div className="col-lg-5">
            <Table responsive striped hover>
              <thead>
                <tr className="text-center">
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Status</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Total Bookings</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={2} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !results.length && (
                    <td colSpan={2} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {results.map((result: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td>{ledgerStatus[result.status].label}</td>
                    <td>{result.count}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-lg-7">
            <ReactEChartsCore
              echarts={echarts}
              option={makePieChartOptions(chartData)}
              style={{ height: '18.75rem' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ReportBookingsLedgerStatus
