import { Card, Table } from 'react-bootstrap'
import { app } from 'config'

interface BookingTravelWithProps {
  className?: string;
  bookings: any[];
}

function BookingTravelWith({ className, bookings }: BookingTravelWithProps) {
  return (
    <Card className={className}>
      <Card.Body>
        <p className="fw-bold text-900">Linked Bookings</p>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">
                <h6 className="text-700">Booking Ref</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">Lead Passenger</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">Cabin</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">Agency</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {!bookings.length ? (
              <tr>
                <td
                  colSpan={5}
                  className="text-center fs-10 text-800 text-nowrap"
                >
                  No linked bookings.
                </td>
              </tr>
            ) : (
              bookings.map((booking) => (
                <tr key={`linked-booking-${booking.id}`}>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {booking.ref_id}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {booking.first_pax}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {booking.cabin_no}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {booking.agency_id === app.agencyId
                      ? app.agencyName
                      : 'External Agency'}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default BookingTravelWith
