import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import CustomDateInput from 'components/common/CustomDateInput'
import dayjs from 'dayjs'

const DateForm = ({ register, errors, setValue, contact }) => {
  const [date, setDate] = useState(null)

  const name = 'created_at'

  useEffect(() => {
    if (contact) {
      setDate(contact.created_at)
    }
  }, [])

  /**
   * Handle date input change
   */
  const handleDateChange = date => {
    // We get the user's device's time
    const hms = dayjs().format('HH:mm:ss')

    // We enforce the client time on the date
    date = dayjs(date).format(`YYYY-MM-DDT[${hms}][Z]`)

    setDate(new Date(date))
    setValue(name, date)
  }

  return (
    <>
      <DatePicker
        selected={date}
        dateFormat="dd/MM/yyyy"
        onChange={handleDateChange}
        customInput={
          <CustomDateInput
            formControlProps={{
              placeholder: 'Creation Date',
              ...register('created_at'),
            }}
            errorMessage={errors[name]?.message}
            isInvalid={errors[name]}
            isValid={Object.keys(errors).length > 0 && !errors[name]}
          />
        }
      />
    </>
  )
}

DateForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
}

export default DateForm
