import { useEffect, useState } from 'react'
import { Button, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { app } from 'config'
import AppClipboard from 'components/common/AppClipboard'
import reportService from 'services/report.service'
import useBooking from 'hooks/useBooking'
import SubtleBadge from 'components/common/SubtleBadge'
import LedgerFileRefModal from 'modules/simple-crm/components/ledgers/LedgerFileRefModal'

function ToolBookingsWithoutFileRef() {
  const { formatViewRoute } = useBooking()

  const [activeLedger, setActiveLedger] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [results, setResults] = useState<any[]>([])
  const [showFileRefModal, setShowFileRefModal] = useState<boolean>(false)

  useEffect(() => {
    getBookingsWithoutFileRef()
  }, [])

  /**
   * Fetches the bookings without File Ref.
   */
  const getBookingsWithoutFileRef = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getBookingsWithoutFileRef()

    setResults(data)
    setLoading(false)
  }

  /**
   * Handles the event when the File Ref modal is closed.
   */
  const handleModalClose = () => {
    setShowFileRefModal(false)
    setActiveLedger(null)
  }

  /**
   * Handles the event when the "Set" button is clicked.
   */
  const handleSetClick = (booking: any) => {
    setActiveLedger(booking.ledger)
    setShowFileRefModal(true)
  }

  /**
   * Handles the event when the file reference is updated.
   */
  const onFileRefUpdate = () => {
    setShowFileRefModal(false)
    getBookingsWithoutFileRef()
  }

  return (
    <div>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th scope="col">
              <h6 className="text-700 fw-semibold">ID</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">MSC Ref</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Status</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Action</h6>
            </th>
          </tr>
        </thead>
        <tbody className="fs-10">
          <tr>
            {loading && (
              <td colSpan={4} className="text-center">
                <Spinner />
              </td>
            )}
          </tr>
          <tr>
            {!loading && !results.length && (
              <td colSpan={4} className="text-center">
                Empty results
              </td>
            )}
          </tr>
          {results.map((result) => (
            <tr className="text-primary fw-semibold" key={result.id}>
              <td>
                <Link to={formatViewRoute(result.id)}>
                  {app.bookingPrefix}
                  {result.id}
                </Link>
              </td>
              <td>
                <Link to={formatViewRoute(result.id)}>{result.ref_id}</Link>
                <AppClipboard text={result.ref_id} />
              </td>
              <td>
                <SubtleBadge
                  className="fs-11"
                  pill
                  bg={(app.status as any)[result.status].variant}
                >
                  {result.status}
                </SubtleBadge>
              </td>
              <td>
                <Button size="sm" onClick={() => handleSetClick(result)}>
                  Set
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LedgerFileRefModal
        show={showFileRefModal}
        ledger={activeLedger}
        onSuccess={onFileRefUpdate}
        onClose={handleModalClose}
      />
    </div>
  )
}

export default ToolBookingsWithoutFileRef
