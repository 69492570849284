import { Card, Table } from 'react-bootstrap'
import dayjs from 'dayjs'
import AppClipboard from 'components/common/AppClipboard'

interface BookingPassengersProps {
  className?: string;
  passengers: any[];
}

function BookingPassengers({ className, passengers }: BookingPassengersProps) {
  return (
    <Card className={className}>
      <Card.Body>
        <p className="fw-bold text-900">Passengers</p>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">
                <h6 className="text-700">Name</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">DOB</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">Age</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">Gender</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">MSC Voyagers Club</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {!passengers.length ? (
              <tr>
                <td
                  colSpan={5}
                  className="text-center fs-10 text-800 text-nowrap"
                >
                  No passengers.
                </td>
              </tr>
            ) : (
              passengers.map((passenger) => (
                <tr key={`passenger-${passenger.id}`}>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {passenger.first_name} {passenger.last_name}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {dayjs(passenger.dob).format('DD-MM-YYYY')}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {passenger.age}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {passenger.gender}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {passenger.membership_no ? (
                      <>
                        {passenger.membership_no} - {passenger.membership_type}
                        <AppClipboard text={passenger.membership_no} />
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default BookingPassengers
