import { createSlice, Slice } from '@reduxjs/toolkit'

export const ledgerSlice: Slice = createSlice({
  name: 'ledger',

  initialState: {
    showTransactionModal: false,
  },

  reducers: {
    /**
     * Shows or hides the transaction modal.
     */
    toggleTransactionModal: (state, { payload }) => {
      state.showTransactionModal = payload
    },
  },
})

export const { toggleTransactionModal } = ledgerSlice.actions

export default ledgerSlice.reducer as any
