import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormEvent, ChangeEvent, useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'

import ConfirmationModal from 'components/common/ConfirmationModal'
import Flex from 'components/common/Flex'

import ledgerService from 'services/ledger.service'

interface LedgerFileRefModalProps {
  ledger: any;
  show: boolean;
  onClose: Function;
  onSuccess: Function;
}

const LedgerFileRefModal = ({
  ledger,
  onClose,
  onSuccess,
  show,
}: LedgerFileRefModalProps) => {
  const [fileRef, setFileRef] = useState('')

  // Whether the file reference is already in use
  const [fileRefInUse, setFileRefInUse] = useState(false)

  // The booking ID associated with the file reference
  const [fileRefBookingId, setFileRefBookingId] = useState(null)

  const [loading, setLoading] = useState(false)

  // Whether the file reference is removable
  const [removeable, setRemovable] = useState(false)

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [validated, setValidated] = useState(false)

  // Whether the file reference is being validated
  const [validating, setValidating] = useState(false)

  // If the modal's visibility changes...
  useEffect(() => {
    // Mark the form as not validated when modal's visibility changes
    setValidated(false)

    if (show) {
      const currentFileRef = ledger?.file_ref || ''

      // If the ledger has a file reference, make it removable
      if (currentFileRef) {
        setRemovable(true)
      }

      // Set the file reference with its current value
      setFileRef(currentFileRef)
    }
  }, [show])

  /**
   * Closes the modal and resets the form.
   */
  const closeModal = () => {
    // Reset the form
    setFileRef('')
    setFileRefInUse(false)
    setFileRefBookingId(null)
    setRemovable(false)

    onClose()
  }

  /**
   * Handles the form submission.
   */
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    const formEl = event.currentTarget
    event.preventDefault()

    const valid = await validateFileRef()

    if (!valid) {
      setFileRefInUse(true)
      toast('File reference is already in use!')
      return
    }

    // Mark the form as validated
    setValidated(true)

    // Check if the form is valid
    if (formEl.checkValidity()) {
      updateFileRef()
    }
  }

  /**
   * Handles the removal of the file reference.
   */
  const handleRemove = () => {
    setFileRef('')
    setShowDeleteConfirmation(true)
  }

  /**
   * Confirms the removal of the file reference.
   */
  const removeFileRef = () => {
    setShowDeleteConfirmation(false)
    updateFileRef({ toastMsg: 'File reference removed!' })
  }

  /**
   * Updates the ledger's file reference.
   */
  const updateFileRef = async (
    options = { toastMsg: 'File reference updated!' }
  ) => {
    setLoading(true)

    const { error } = await ledgerService.updateFileRef(ledger.id, fileRef)

    setLoading(false)

    if (error) {
      toast(error?.message || 'Unexpected Error')
      return
    }

    toast(options?.toastMsg)

    // Inform the parent component that the file ref has been updated
    onSuccess()

    closeModal()
  }

  /**
   * Validates the file reference.
   */
  const validateFileRef = async () => {
    setValidating(true)

    const { data }: any = await ledgerService.validateFileRef(
      ledger.id,
      fileRef
    )

    setValidating(false)

    // Set the booking ID associated with the file reference
    // so the user can view the booking
    setFileRefBookingId(data.booking_id)

    // Valid means the file reference is not in use
    return !data.in_use
  }

  return (
    <>
      <Modal show={show} onHide={closeModal} keyboard={false}>
        <Modal.Body>
          <Flex alignItems="center" className="py-3 justify-content-center">
            Update Ledger's File Reference
          </Flex>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <label htmlFor="file_ref">File Reference</label>
              <Form.Control
                required
                disabled={validating || loading}
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  setFileRef(e.target.value)
                }
                value={fileRef}
                placeholder={'File Reference'}
                name="file_ref"
                type="text"
                id="file_ref"
              />
            </Form.Group>
            {fileRefInUse ? (
              <div className="d-flex justify-content-end gap-2">
                <Button
                  type="button"
                  variant="light"
                  onClick={closeModal}
                  disabled={validating || loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="success"
                  disabled={validating || loading}
                >
                  Retry
                </Button>
                <a
                  href={`/simplycruises/bookings/${fileRefBookingId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    type="button"
                    variant="primary"
                    disabled={validating || loading}
                  >
                    View Booking
                    <FontAwesomeIcon
                      icon="external-link-alt"
                      className="ms-2"
                    />
                  </Button>
                </a>
              </div>
            ) : (
              <div className="d-flex justify-content-between gap-2">
                {removeable ? (
                  <Button
                    type="button"
                    variant="falcon-danger"
                    disabled={validating || loading}
                    onClick={handleRemove}
                  >
                    Remove
                  </Button>
                ) : (
                  <>&nbsp;</>
                )}
                <div className="d-flex gap-2">
                  <Button
                    type="button"
                    variant="light"
                    onClick={closeModal}
                    disabled={validating || loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={validating || fileRefInUse || loading}
                  >
                    {validating ? 'Validating...' : 'Save'}
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        show={showDeleteConfirmation}
        handleClose={() => setShowDeleteConfirmation(false)}
        handleConfirm={() => removeFileRef()}
        confirmationText="Do you really want to remove this file ref?"
        confirmButtonText="Delete"
      />
    </>
  )
}

export default LedgerFileRefModal
