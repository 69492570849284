import qs from 'query-string'

import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/reports`

/**
 * Gets the number of bookings per month.
 */
export const getBookingsPerMonth = async (query: any = {}) =>
  callExternalApi({
    config: {
      url: `${endpoint}/bookings-per-month?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the number of bookings per ship.
 */
export const getBookingsPerShip = async (query: any = {}) =>
  callExternalApi({
    config: {
      url: `${endpoint}/bookings-per-ship?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the number of bookings per status.
 */
export const getBookingsPerStatus = async (query: any = {}) =>
  callExternalApi({
    config: {
      url: `${endpoint}/bookings-per-status?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the sum of revenue per month.
 */
export const getCruiseRevenuePerMo = async (query: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/cruise-revenue-per-mo?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the bookings with due balances by month.
 */
export const getDueBalancesByMonth = async (query: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/due-balances-by-month?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the sum of `balances` by year.
 */
export const getDueBalancesByYear = async (query: any) =>
  callExternalApi({
    config: {
      url: `${endpoint}/due-balances-by-year?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the sum of total revenue per sailing.
 */
export const getRevenuePerSailing = async (query: any = {}) =>
  callExternalApi({
    config: {
      url: `${endpoint}/revenue-per-sailing?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the sum of total revenue per ship.
 */
export const getRevenuePerShip = async (query: any = {}) =>
  callExternalApi({
    config: {
      url: `${endpoint}/revenue-per-ship?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Gets the bookings with pending tickets.
 */
export const getBookingsWithPendingTickets = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/bookings-with-pending-tickets`,
      method: 'GET',
    },
  })

/**
 * Gets the bookings without Ledger File Ref
 */
export const getBookingsWithoutFileRef = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/bookings-without-file-ref`,
      method: 'GET',
    },
  })

/**
 * Get bookings with outstanding ledger.
 */
export const getOutstandingBookings = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/outstanding-bookings`,
      method: 'GET',
    },
  })

/**
 * Get bookings with outstanding ledger.
 */
export const getLedgersStatus = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/ledgers-status`,
      method: 'GET',
    },
  })

const reportService = {
  getBookingsPerMonth,
  getBookingsPerShip,
  getBookingsPerStatus,
  getBookingsWithPendingTickets,
  getBookingsWithoutFileRef,
  getCruiseRevenuePerMo,
  getDueBalancesByMonth,
  getDueBalancesByYear,
  getOutstandingBookings,
  getRevenuePerShip,
  getRevenuePerSailing,
  getLedgersStatus,
}

export default reportService
