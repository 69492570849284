import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function LoginIntro() {
  return (
    <div className="w-auto d-none d-lg-block">
      <div className="d-flex mt-3">
        <div>
          <FontAwesomeIcon
            icon="check-circle"
            className="text-primary me-3 mt-1"
          />
        </div>
        <div>
          <div className="fs-8 fw-bold text-black">Get started quickly</div>
          <div className="text-muted">
            Integrate with developer-friendly APIs or choose low-code.
          </div>
        </div>
      </div>
      <div className="d-flex mt-4">
        <div>
          <FontAwesomeIcon
            icon="check-circle"
            className="text-primary me-3 mt-1"
          />
        </div>
        <div>
          <div className="fs-8 fw-bold text-black">
            Support any business model
          </div>
          <div className="text-muted">
            Host code that you don't want to share with the world in private.
          </div>
        </div>
      </div>
      <div className="d-flex mt-4">
        <div>
          <FontAwesomeIcon
            icon="check-circle"
            className="text-primary me-3 mt-1"
          />
        </div>
        <div>
          <div className="fs-8 fw-bold text-black">
            Join millions of businesses
          </div>
          <div className="text-muted">
            Flowbite is trusted by ambitious startups and enterprises of every
            size.
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 mt-8">
        <span className="cursor-pointer text-muted fs-10">About</span>
        <span className="cursor-pointer text-muted fs-10">
          Term & Conditions
        </span>
        <span className="cursor-pointer text-muted fs-10">Contact</span>
      </div>
    </div>
  )
}

export default LoginIntro
