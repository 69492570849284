import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/dashboard`

/**
 * Get bookings over time
 */
export const getBookingsOverTime = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/bookings-over-time`,
      method: 'GET',
    },
  })

/**
 * Get contacts over time
 */
export const getContactsOverTime = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/contacts-over-time`,
      method: 'GET',
    },
  })

/**
 * Get chart data
 */
export const getMonthlyBookings = async (type: string) =>
  callExternalApi({
    config: {
      url: `${endpoint}/monthly-bookings/${type}`,
      method: 'GET',
    },
  })

/**
 * Get chart data
 */
export const getMonthlyContacts = async (type: string) =>
  callExternalApi({
    config: {
      url: `${endpoint}/monthly-contacts/${type}`,
      method: 'GET',
    },
  })

/**
 * Get chart data
 */
export const getMonthlyRevenue = async (type: string) =>
  callExternalApi({
    config: {
      url: `${endpoint}/monthly-revenue/${type}`,
      method: 'GET',
    },
  })

/**
 * Get bookings over time
 */
export const getRevenueOverTime = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/revenue-over-time`,
      method: 'GET',
    },
  })

/**
 * Get chart data
 */
export const getMonthlyPassengers = async (type: string) =>
  callExternalApi({
    config: {
      url: `${endpoint}/monthly-passengers/${type}`,
      method: 'GET',
    },
  })

/**
 * Get passengers over time
 */
export const getPassengersOverTime = async () =>
  callExternalApi({
    config: {
      url: `${endpoint}/passengers-over-time`,
      method: 'GET',
    },
  })

const dashboardService = {
  getBookingsOverTime,
  getContactsOverTime,
  getMonthlyBookings,
  getMonthlyContacts,
  getMonthlyRevenue,
  getRevenueOverTime,
}

export default dashboardService
