import React, { createRef, useEffect } from 'react'
import { PostcodeLookup } from '@ideal-postcodes/postcode-lookup'

const PostcodeLookupComponent = (props) => {
  const context = createRef()

  useEffect(() => {
    PostcodeLookup.setup({
      apiKey: process.env.REACT_APP_IDEAL_POSTCODE_KEY,
      context: context.current,
      buttonLabel: 'Search Address',
      buttonClass: 'mt-3 px-5 btn btn-primary',
      inputClass: 'form-control',
      selectClass: 'form-control mt-3 mb-2',
      ...props
    })
  }, [])

  return <div ref={context}></div>
}

export default PostcodeLookupComponent
