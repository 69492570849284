import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Gravatar from 'react-gravatar'

import Avatar from 'components/common/Avatar'
import authService from 'services/auth.service'
import useAuth from 'hooks/useAuth'

const ProfileDropdown = () => {
  const user = useSelector((state) => state.auth.user)

  const { clearAccessToken } = useAuth()

  /**
   * Handle the user logout.
   */
  const handleLogout = async () => {
    // Log out the user from the server.
    await authService.logout()

    // Clear the access token from the local storage.
    clearAccessToken()

    // Redirect the user to the login page.
    window.location.href = '/login'
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        {user?.email ? <Gravatar email={user.email} size={32} className="rounded-circle" /> : <Avatar />}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item className="fw-bold text-warning" href="#">
            {user?.full_name}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="#" onClick={handleLogout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
