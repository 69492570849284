import Lottie from 'lottie-react'
import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import celebration from './lottie/celebration.json'
import { useNavigate } from 'react-router-dom'

const SuccessUpdate = () => {
  const navigate = useNavigate()

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Contact is updated!</h4>
        </Col>
        <Button variant="primary" className="px-5 my-3" onClick={() => navigate(-1)}>
          Back To Contact
        </Button>
      </Row>
    </>
  )
}

export default SuccessUpdate
