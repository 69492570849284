import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import * as echarts from 'echarts/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import cn from 'classnames'
import dayjs from 'dayjs'

import useUtils from 'hooks/useUtils'
import useChart from 'hooks/useChart'
import useReport from 'hooks/useReport'

import reportService from 'services/report.service'

interface ReportRevenuePerSailingProps {
  className?: string;
}

function ReportRevenuePerSailing({ className }: ReportRevenuePerSailingProps) {
  const { makeBarChartOptions } = useChart()
  const { currentYear, monthOptions, yearOptions } = useReport()
  const { monify } = useUtils()

  const [chartXAxis, setChartXAxis] = useState<string[]>([])
  const [chartData, setChartData] = useState<any[]>([])
  const [topSailingsLength, setTopSailingsLength] = useState(0)

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  // We add 1 to month because dayjs month starts at 0
  const currentMonth = useMemo(() => dayjs().month() + 1, [])
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)

  const [selectedYear, setSelectedYear] = useState(currentYear)

  // Get results on initial load, also when selected month or year changes
  useEffect(() => {
    getResults()
  }, [selectedMonth, selectedYear])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getRevenuePerSailing({
      month: selectedMonth,
      year: selectedYear,
    })

    setResults(data)

    // Sort by revenue, descending
    const top10Sailings: any[] = [...data]
      .sort(
        (a: any, b: any) =>
          +b.total_revenue_per_ship - +a.total_revenue_per_ship
      )
      .slice(0, 10)

    setTopSailingsLength(top10Sailings.length)

    setChartXAxis(
      top10Sailings.map(
        (result: any) =>
          `${result.ship_name} [${dayjs(result.sailing_date).format(
            'DD-MM-YYYY'
          )}]`
      )
    )

    setChartData(
      top10Sailings.map((result: any) => result.total_revenue_per_ship)
    )

    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Revenue Per Sailing</p>
          <div className="d-flex flex-between-center gap-3">
            <Form.Group className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon="filter" className="me-2" />
              <Form.Select
                value={selectedMonth}
                onChange={(e: any) => setSelectedMonth(e.target.value)}
                disabled={loading}
              >
                {monthOptions.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center gap-2">
              <Form.Select
                value={selectedYear}
                onChange={(e: any) => setSelectedYear(e.target.value)}
                disabled={loading}
              >
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <hr />
        <div className="row g-5">
          <div className="col-lg-6">
            <Table responsive striped hover>
              <thead>
                <tr className="text-center">
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Sailing Date</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Ship Name</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Total Bookings</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Total Revenue</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={4} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !results.length && (
                    <td colSpan={4} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {results.map((result: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td>{dayjs(result.sailing_date).format('DD-MM-YYYY')}</td>
                    <td>{result.ship_name}</td>
                    <td>{result.total_bookings_per_ship}</td>
                    <td>{monify(result.total_revenue_per_ship)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-lg-6">
            <p className="fw-bold m-0">
              Top {!loading && topSailingsLength} Sailings with the Most Revenue
            </p>
            <ReactEChartsCore
              echarts={echarts}
              option={makeBarChartOptions(chartXAxis, chartData)}
              style={{ height: '18.75rem' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ReportRevenuePerSailing
