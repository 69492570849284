import { toast } from 'react-toastify'
import { useState, useCallback, useEffect, useRef } from 'react'
import { Card, Col, Row } from 'react-bootstrap'

import { getContactList } from 'services/contact.service'
import { columns } from 'modules/simple-crm/components/contacts/ContactsLayout'
import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'
import ContactsHeader from 'modules/simple-crm/components/contacts/ContactsHeader'

const ContactsPage = () => {
  const [contacts, setContacts] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  const [page, setPage] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(0)
  const [totalResultsCount, setTotalResultsCount] = useState(0)

  const fetchIdRef = useRef(0)

  useEffect(() => {
    fetchContactList({ page: page, search })
  }, [search, page])

  const fetchContactList = useCallback(async ({ page = 1, search = '' }) => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current

    // Set the loading state
    setLoading(true)

    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      const { data, error } = await getContactList(page, search)

      if (error) {
        setLoading(false)
        toast(error.message || 'Unexpected Error')
        return
      }

      setContacts(data.data)
      setPageCount(Math.ceil(data.total / data.per_page))
      setPerPage(data.per_page)
      setTotalResultsCount(data.total)
      setLoading(false)
    }
  }, [])

  return (
    <Row className="gx-3">
      <Col xxl={12}>
        <AdvanceTableWrapper
          columns={columns}
          data={contacts}
          selection
          selectionColumnWidth={52}
          sortable
          pagination
          perPage={perPage}
          rowCount={contacts.length}
          loading={loading}
          pageCount={pageCount}
          totalResultsCount={totalResultsCount}
          setPage={setPage}
        >
          <Card>
            <Card.Header className="border-bottom border-200 px-0">
              <ContactsHeader
                table
                layout="table-view"
                fetchContactList={fetchContactList}
                setSearch={setSearch}
              />
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-body-tertiary align-middle"
                rowClassName="btn-reveal-trigger align-middle"
                tableProps={{
                  size: 'sm',
                  className: 'fs-10 mb-0 overflow-hidden',
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>
    </Row>
  )
}

export default ContactsPage
