import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface AppTooltipProps {
  id: string;
  children: React.ReactNode;
  title: string;
}

const AppTooltip = ({ id, children, title }: AppTooltipProps) => (
  <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
    <span>{children}</span>
  </OverlayTrigger>
)

export default AppTooltip
