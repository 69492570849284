import { Card, Table } from 'react-bootstrap'

import useUtils from 'hooks/useUtils'

interface BookingActivitiesProps {
  className?: string;
  activities: any[];
}

function BookingActivities({ className, activities }: BookingActivitiesProps) {
  const { formatDate, monify } = useUtils()

  return (
    <Card className={className}>
      <Card.Body>
        <p className="fw-bold text-900">Excursions</p>
        <Table responsive striped hover>
          <thead>
            <tr>
              <th scope="col">
                <h6 className="text-700">Activity</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">Passengers</h6>
              </th>
              <th scope="col">
                <h6 className="text-700">Cost</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {activities.length ? (
              activities.map((activity) => (
                <tr key={`activity-${activity.id}`}>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    <div>
                      <p className="m-0 fw-bold">
                        {activity.code} -{' '}
                        {formatDate(activity.occur_date, 'DD/MM/YYYY')}
                      </p>
                      <p className="m-0">{activity.desc}</p>
                    </div>
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {activity.passengers.map((passenger: any) => (
                      <div
                        key={`activity-${activity.id}-passenger-${passenger.id}`}
                      >
                        {passenger.first_name} {passenger.last_name}
                      </div>
                    ))}
                  </td>
                  <td className="fs-10 text-800 fw-semibold text-nowrap">
                    {monify(activity.price)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={3}
                  className="text-center fs-10 text-800 text-nowrap"
                >
                  No onboard activities.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default BookingActivities
