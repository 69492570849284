import { useCallback, useEffect, useState } from 'react'
import { Card, Tab, Row, Col, Nav, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import * as echarts from 'echarts/core'
import { LineChart, BarChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import { camelize } from 'helpers/utils'

import Flex from 'components/common/Flex'
import Spinner from 'components/common/Spinner'

import useChart from 'hooks/useChart'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  BarChart,
])

const DashboardMonthlyEntries = ({ apiFn, title }) => {
  const { makeBarChartOptions } = useChart()

  const [data, setData] = useState([])
  const [yearData, setYearData] = useState([])

  useEffect(() => {
    fetchMonthData()
    fetchYearData()
  }, [])

  const getChartCode = useCallback((months, monthsData) => {
    return (
      <ReactEChartsCore
        echarts={echarts}
        option={makeBarChartOptions(months, monthsData)}
        style={{ height: '18.75rem' }}
      />
    )
  }, [])

  const fetchMonthData = async () => {
    const { data, error } = await apiFn('month')

    if (error) {
      toast(error.message || 'Unexpected Error')
      return
    }
    setData(data)
  }

  const fetchYearData = async () => {
    const { data, error } = await apiFn('year')

    if (error) {
      toast(error.message || 'Unexpected Error')
      return
    }
    setYearData(data)
  }

  if (!data) {
    return <Spinner />
  }

  let months = Object.keys(data)
  let monthsData = Object.values(data)

  let years = Object.keys(yearData)
  let yearsData = Object.values(yearData)

  return (
    <Card className="shadow-none shadow-show-xl scrollbar">
      <Tab.Container defaultActiveKey="month">
        <Card.Header>
          <Row>
            <Col>
              <Flex>
                <h5
                  className="mb-0 hover-actions-trigger text-truncate text-nowrap"
                  id={camelize(title)}
                >
                  {title}
                </h5>
              </Flex>
            </Col>
            <Col className="col-md-auto col-12">
              <Row className="d-inline-block">
                <Col>
                  <Nav variant="pills" className="nav-pills-falcon m-0">
                    <Nav.Item>
                      <Nav.Link as={Button} size="sm" eventKey="month">
                        Month
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Button} size="sm" eventKey="year">
                        Year
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Tab.Content>
            <Tab.Pane eventKey="month">
              {getChartCode(months, monthsData)}
            </Tab.Pane>
            <Tab.Pane eventKey="year" className="overflow-auto scrollbar">
              <div style={{ maxHeight: '25rem' }}>
                {getChartCode(years, yearsData)}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
    </Card>
  )
}

export default DashboardMonthlyEntries
