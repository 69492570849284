import { useEffect, useState } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import cn from 'classnames'

import AppClipboard from 'components/common/AppClipboard'

import useBooking from 'hooks/useBooking'
import useUtils from 'hooks/useUtils'

import reportService from 'services/report.service'

interface ReportDueBalancesProps {
  className?: string;
}

function ReportDueBalances({ className }: ReportDueBalancesProps) {
  const { formatViewRoute } = useBooking()
  const { formatDate } = useUtils()

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  useEffect(() => {
    getResults()
  }, [])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getBookingsWithPendingTickets()

    setResults(data)
    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Bookings with Pending Tickets</p>
        </div>
        <hr />
        <Table responsive striped hover>
          <thead>
            <tr className="text-center">
              <th scope="col">
                <h6 className="text-700 fw-semibold">Ref</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Contact</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Ship</h6>
              </th>
              <th scope="col">
                <h6 className="text-700 fw-semibold">Sailing Date</h6>
              </th>
            </tr>
          </thead>
          <tbody className="fs-10">
            <tr>
              {loading && (
                <td colSpan={4} className="text-center">
                  <Spinner />
                </td>
              )}
            </tr>
            <tr>
              {!loading && !results.length && (
                <td colSpan={4} className="text-center">
                  Empty results
                </td>
              )}
            </tr>
            {results.map((result: any, index: number) => (
              <tr key={index} className="text-center">
                <td className="fw-semibold">
                  <Link to={formatViewRoute(result.id)}>{result.ref_id}</Link>
                  <AppClipboard text={result.ref_id} />
                </td>
                <td>{`${result.contact?.first_name} ${result.contact?.last_name}`}</td>
                <td>{result.ship_name}</td>
                <td>{formatDate(result.sailing_date)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default ReportDueBalances
