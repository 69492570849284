/* eslint-disable react/prop-types */
import classNames from 'classnames'
import React from 'react'
import { Form } from 'react-bootstrap'
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
} from 'react-table'
import Spinner from '../Spinner'

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, className, ...rest }, ref) => {
    const defaultRef = React.useRef()

    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <Form.Check
        type="checkbox"
        className={classNames(
          'form-check mb-0 d-flex align-items-center',
          className,
        )}
      >
        <Form.Check.Input
          type="checkbox"
          className="mt-0"
          ref={resolvedRef}
          {...rest}
        />
      </Form.Check>
    )
  },
)

const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  selectionHeaderClassname,
  pagination,
  perPage = 10,
  pageCount: controlledPageCount,
  totalResultsCount,
  loading,
  setPage,
}) => {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState: { pageIndex: 0 },
      disableSortBy: !sortable,
      pageCount: controlledPageCount,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            headerProps: {
              className: selectionHeaderClassname,
              style: {
                width: selectionColumnWidth,
              },
            },
            cellProps: {
              style: {
                width: selectionColumnWidth,
              },
            },
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    },
  )

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    const page = pageIndex + 1
    setPage(page)
  }, [pageIndex])

  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children),
        })
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter,
          })
        } else {
          return child
        }
      }
    })
  }

  const showFrom = (pageIndex * perPage) + 1
  const showTo = (pageIndex + 1) * perPage < totalResultsCount
    ? (pageIndex + 1) * perPage
    : totalResultsCount

  return (
    // <>
    //   {React.Children.map(children, child => {
    //     if (child.props.table) {
    //       return React.cloneElement(child, {
    //         ...child.props,
    //         getTableProps,
    //         headers,
    //         page,
    //         prepareRow,
    //         canPreviousPage,
    //         canNextPage,
    //         nextPage,
    //         previousPage,
    //         gotoPage,
    //         pageCount,
    //         pageIndex,
    //         selectedRowIds,
    //         pageSize,
    //         setPageSize
    //       });
    //     } else {
    //       return child;
    //     }
    //   })}
    // </>
    <>
      <>{recursiveMap(children)}</>

      <span>
        {loading ? (
            <Spinner />
          )
          : (
            <span colSpan="10000">
              Showing {showFrom} - {showTo} of {totalResultsCount | 0} results
            </span>
          )
        }
      </span>
    </>
  )
}

export default AdvanceTableWrapper
