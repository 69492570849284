import { useEffect, useState } from 'react'
import { Button, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { app } from 'config'
import AppClipboard from 'components/common/AppClipboard'
import reportService from 'services/report.service'
import useBooking from 'hooks/useBooking'
import SubtleBadge from 'components/common/SubtleBadge'
import useUtils from 'hooks/useUtils'
import LedgerSettlerModal from 'modules/simple-crm/components/ledgers/LedgerSettlerModal'

function ToolBookingsOutstanding() {
  const { formatViewRoute, computeProfit } = useBooking()
  const { monify } = useUtils()

  const [activeLedger, setActiveLedger] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [results, setResults] = useState<any[]>([])
  const [showSettlerForm, setShowSettlerForm] = useState<boolean>(false)

  useEffect(() => {
    getOutstandingBookings()
  }, [])

  /**
   * Fetches the bookings without File Ref.
   */
  const getOutstandingBookings = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getOutstandingBookings()

    setResults(data)
    setLoading(false)
  }

  /**
   * Handles the event when the modal is closed.
   */
  const handleModalClose = () => {
    setShowSettlerForm(false)
    setActiveLedger(null)
  }

  /**
   * Handles the event when the "Settle" button is clicked.
   */
  const handleSettleClick = (booking: any) => {
    setActiveLedger(booking.ledger)
    setShowSettlerForm(true)
  }

  /**
   * Handles the event when a ledger is settled.
   */
  const handleSettled = () => {
    setShowSettlerForm(false)
    getOutstandingBookings()
  }

  return (
    <div>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th scope="col">
              <h6 className="text-700 fw-semibold">ID</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">MSC Ref</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Status</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">File Ref</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Profit</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Action</h6>
            </th>
          </tr>
        </thead>
        <tbody className="fs-10">
          <tr>
            {loading && (
              <td colSpan={6} className="text-center">
                <Spinner />
              </td>
            )}
          </tr>
          <tr>
            {!loading && !results.length && (
              <td colSpan={6} className="text-center">
                Empty results
              </td>
            )}
          </tr>
          {results.map((result) => (
            <tr className="text-primary fw-semibold" key={result.id}>
              <td>
                <Link to={formatViewRoute(result.id)}>
                  {app.bookingPrefix}
                  {result.id}
                  {' / '}
                  {app.ledgerPrefix}
                  {result.ledger.id}
                </Link>
              </td>
              <td>
                <Link to={formatViewRoute(result.id)}>{result.ref_id}</Link>
                <AppClipboard text={result.ref_id} />
              </td>
              <td>
                <SubtleBadge
                  className="fs-11"
                  pill
                  bg={(app.status as any)[result.status].variant}
                >
                  {result.status}
                </SubtleBadge>
              </td>
              <td>
                {result.ledger.file_ref}
                <AppClipboard text={result.ledger.file_ref} />
              </td>
              <td>{monify(computeProfit(result))}</td>
              <td>
                <Button size="sm" onClick={() => handleSettleClick(result)}>
                  Settle
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LedgerSettlerModal
        ledgerId={activeLedger?.id}
        show={showSettlerForm}
        onClose={handleModalClose}
        onSuccess={handleSettled}
      />
    </div>
  )
}

export default ToolBookingsOutstanding
