import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useAppContext } from 'Main'

import NavbarTop from 'components/navbar/top/NavbarTop'
import Footer from 'components/footer/Footer'

const SelectCRMLayout = () => {
  const { hash, pathname } = useLocation()

  const { config: { isFluid } } = useAppContext()

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    }, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <div className="content">
        <NavbarTop />
        <Outlet />
        <Footer />
      </div>
    </div>
  )
}

export default SelectCRMLayout
