import {
  faOutdent
} from '@fortawesome/free-solid-svg-icons'
import { appRoutes } from '../../simple-crm/routes/siteMaps'

const ROOT_PATH = '/simplyjourneys'
export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: `${ROOT_PATH}/`,
    }
  ]
}
// export const appRoutes = {
//   label: 'app',
//   children: [
//     {
//       name: 'Contacts',
//       icon: 'user',
//       to: '/simplycruises/contacts',
//       active: true
//     },
//   ]
// };

export const pagesRoutes = {
  label: 'More..',
  children: [
    {
      name: 'Back To CRMs',
      icon: faOutdent,
      to: '/',
      active: false
    },
  ]
}


const sitemap = [
  dashboardRoutes,
  // appRoutes,
  pagesRoutes,
]

export default sitemap
